<template>
  <div>
    <content-header :title="detail.Name" :breadList="['运营中心', '运营计划']">
      <div slot="tools" class="top-tool-wrap">
        <i class="el-icon-loading g-icon-gray" v-if="loading" />
        <i
          class="el-icon-refresh-right g-icon-gray"
          v-else
          @click="handleRefresh"
        />
        <el-button  round  @click="gotoDetailTemplate" >
          按客户统计
        </el-button>
        <el-button slot="tools" type="primary" round>
          按员工统计
        </el-button>
      </div>
      <!-- <div slot="title" class="linkBox">
        <router-link :to="`/plan/detail/${planId}`" class="g-link">
          活动效果分析
        </router-link>
      </div> -->
    </content-header>
    <div class="condition-wrap" v-if="!loading">
      <span class="cond-item">{{ detail.TriggerType }}</span>
      <i class="el-icon-time" />
      <span
        class="cond-item"
        v-if="['单次定时群发', '多波次流程画布'].includes(detail.TriggerType)"
        >发布时间：{{ detail.PublishTime }}</span
      >
      <span v-else class="cond-item"
        >起止时间：{{ detail.StartTime }} - {{ detail.EndTime }}</span
      >
    </div>
    <div class="chart-wrap">
      <chart-list :id="planId" ref="chartList" />
    </div>
    <report-tabs :id="planId" ref="reportTabs" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ContentHeader from "@/views/layout/content-header";
import chartList from "./components/report-chart-list";
import ReportTabs from "./components/report-tabs.vue";

import { apiGetPlanDetail } from "@/api/plan";

export default {
  components: {
    ContentHeader,
    chartList,
    ReportTabs,
  },
  data() {
    return {
      loading: true,
      planId: this.$route.params.id,
      showCharts: true,
      detail: {
        _id: "",
        Name: "",
        IsPublic: "1",
        FilterConditionType: "",
        FilterConditionLogic: "",
        FilterGroups: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      allFields: "common/allFields",
      allOperator: "common/allOperator",
    }),
  },
  methods: {
    getPlanDetail() {
      this.loading = true;
      apiGetPlanDetail(this.planId)
        .then((res) => {
          this.detail = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleRefresh() {
      this.$refs.chartList.refresh();
      this.$refs.reportTabs.refresh();
    },
    goback() {
      this.$router.back(-1);
    },
    gotoDetailTemplate() {
      this.$router.push({
        path: `/plan/detail/${this.planId}`,
      });
    },
  },
  mounted() {
    this.getPlanDetail();
    this.handleRefresh();
  },
};
</script>

<style lang="less">
.top-tool-wrap {
  .el-icon-loading,
  .el-icon-refresh-right {
    margin-right: 18px;
  }
  .kehutongji{
    border: 1px solid #66b1ff;
    color: #66b1ff;
  }
}
.condition-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px 20px 22px 20px;
  background: #ffffff;
  border: 1px solid #e2e6e9;
  border-radius: 12px;
  font-size: 12px;
  .cond-item {
    margin-right: 10px;
  }
  > i {
    margin-right: 6px;
    font-size: 16px;
  }
}

.linkBox {
  display: inline-block;
  margin-left: 16px;
  .g-link {
    font-size: 14px;
  }
}
</style>
