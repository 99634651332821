<template>
  <div>
    <!-- <div class="table-title">客户列表</div> -->
    <!-- <div style="text-align: right">
      <img
        @click="showQuery = !showQuery"
        class="showQuery"
        src="@/assets/img/query-more.png"
        alt=""
      />
    </div> -->
    <div class="query-form" v-if="showQuery">
      <i class="el-icon-close" @click="$emit('update:showQuery', false)"></i>
      <el-form
        :inline="true"
        v-if="showQuery"
        label-width="80px"
        ref="searchForm"
      >
        <el-form-item label="姓名:">
          <el-input
            placeholder="姓名"
            v-model="formData.CustomerName"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别:">
          <el-select
            v-model="formData.CustomerSex"
            style="width: 200px"
            clearable
            filterable
          >
            <el-option
              :value="item"
              :label="item"
              v-for="item in CustomerSexList"
              :key="item"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="年龄:">
          <el-select
            v-model="formData.CustomerAge"
            style="width: 200px"
            clearable
            filterable
          >
            <el-option
              :value="item"
              :label="item"
              v-for="item in CustomerAgeList"
              :key="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号:">
          <el-input
            placeholder="手机号"
            v-model="formData.Phone"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="常住省份:">
          <el-select
            v-model="formData.Province"
            style="width: 200px"
            clearable
            filterable
          >
            <el-option
              :value="item"
              :label="item"
              v-for="item in ProvinceList"
              :key="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-button size="medium" type="info" @click="handleQuery" round
          >应用</el-button
        >
        <el-button size="medium" @click="handleReset" round class="resetBtn"
          >重置</el-button
        >
      </el-form>
    </div>

    <el-table :data="tableData" v-loading="loading" class="table-customer">
      <el-table-column prop="CustomerName" label="姓名">
        <template slot-scope="scope">
          <router-link :to="`/customer/info/${scope.row.Id}`" class="g-link">
            <i
              class="iconfont icon-customer"
              style="margin-right: 6px; vertical-align: bottom"
            ></i>
            <span>{{ scope.row.CustomerName }}</span>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="CustomerSex" label="性别"> </el-table-column>
      <el-table-column prop="CustomerAge" label="年龄"> </el-table-column>
      <el-table-column prop="Phone" label="手机号"> </el-table-column>
      <el-table-column prop="Province" label="常住省份"> </el-table-column>
      <!-- <el-table-column prop="address" label="阶段" width="125">
      </el-table-column>
      <el-table-column prop="address" label="所在城市"> </el-table-column> -->
    </el-table>
    <el-pagination
      class="pagination"
      layout="prev, pager, next"
      :total="pagination.totalCount"
      :current-page="pagination.currentPage"
      :page-size="pagination.pageSize"
      @current-change="handlePage"
    >
    </el-pagination>
  </div>
</template>

<script>
import { apiGetCustomers } from "@/api/group";
import { mapGetters } from "vuex";
export default {
  props: {
    showQuery: {
      type: Boolean,
      default: false,
    },
  },
  inject: ["id"],
  computed: {
    ...mapGetters({
      customerFields: "common/customerFields",
    }),
    // 获取学历列表
    CustomerEducationList() {
      const PicklistOptions = this.customerFields.find((item) => {
        return item.APIName === "CustomerEducation";
      }).PicklistOptions;
      return PicklistOptions;
    },
    // 获取性别列表
    CustomerSexList() {
      const PicklistOptions = this.customerFields.find((item) => {
        return item.APIName === "CustomerSex";
      }).PicklistOptions;
      return PicklistOptions;
    },
    // 获取年龄列表
    CustomerAgeList() {
      const PicklistOptions = this.customerFields.find((item) => {
        return item.APIName === "CustomerAge";
      }).PicklistOptions;
      return PicklistOptions;
    },
    // 获取常住省份列表
    ProvinceList() {
      const PicklistOptions = this.customerFields.find((item) => {
        return item.APIName === "Province";
      }).PicklistOptions;
      return PicklistOptions;
    },
  },
  data() {
    return {
      loading: false,
      tableData: [],
      pagination: {
        pageSize: 10,
        totalCount: 0,
        currentPage: 1,
      },
      formData: {
        CustomerName: "",
        date: [],
        CustomerAge: "",
        CustomerSex: "",
        Phone: "",
        Province: "",
      },
    };
  },
  methods: {
    getCustomers() {
      this.loading = true;
      const { pageSize, currentPage } = this.pagination;
      const formData = JSON.parse(JSON.stringify(this.formData));

      const param = {
        offset: pageSize * (currentPage - 1),
        limit: pageSize,
        ...formData,
      };

      apiGetCustomers(this.id, param)
        .then((res) => {
          this.tableData = res.customers;
          this.pagination.totalCount = res.meta.total_count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePage(index) {
      this.pagination.currentPage = index;
      this.getCustomers();
    },
    refresh() {
      this.handlePage(1);
    },
    handleQuery() {
      this.handlePage(1);
    },
    handleReset() {
      this.formData = {
        CustomerName: "",
        date: [],
        CustomerAge: "",
        CustomerSex: "",
        Phone: "",
        Province: "",
      };
    },
  },
  mounted() {
    this.getCustomers();
  },
};
</script>

<style lang="less" scoped>
.table-wrapper {
  .table-customer {
    margin-top: 0;
  }
  .showQuery {
    cursor: pointer;
  }
  .query-form {
    margin: 20px 0;
    background: #f6f7f8;
    border-radius: 6px;
    position: relative;
    padding: 16px 90px 2px 16px;
    .el-icon-close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    /deep/ .el-form {
      display: flex;
      flex-wrap: wrap;
    }
    /deep/ .el-button--info {
      margin-left: auto;
      margin-bottom: 10px;
      height: 40px;
    }
    .resetBtn {
      margin-bottom: 10px;
      height: 40px;
    }
  }
  .table-title {
    margin-bottom: 18px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
  }
  .slider {
    width: 162px;
    // margin-top: 14px;
    margin-left: 8px;
    margin-right: 20px;
    /deep/ .el-slider__bar {
      background: #0483ec;
    }
    /deep/ .el-slider__button {
      background: #0483ec;
      border: none;
    }
  }

  /deep/ .el-range-separator {
    padding: 0;
  }
}
</style>
