<template>
  <div class="edit-drawer">
    <el-drawer
      :visible.sync="exist"
      :direction="direction"
      :before-close="handleClose"
      :show-close="false"
      :wrapperClosable="false"
    >
      <div slot="title" class="title-wrap">
        <div class="left">
          <i class="el-icon-close" @click="handleClose"></i>
          <span>导出</span>
        </div>
        <div class="right">
          <el-button type="text" @click="handleClose">取消</el-button>
          <el-button
            type="primary"
            round
            @click="handleSave"
            :loading="saving"
            :disabled="disabled"
            >保存
          </el-button>
        </div>
      </div>
      <div class="drawer-content">
        <el-form
          :model="formData"
          :rules="formRule"
          ref="form"
          class="edit-form"
          size="small"
          :disabled="disabled"
        >
          <cond-card title="导出文件信息">
            <el-form-item
              label="字段设置"
              prop="FieldType"
              :label-width="LabelWidth"
            >
              <el-radio-group v-model="formData.FieldType" @change="onFieldSet">
                <el-radio
                  :label="item.value"
                  v-for="item in fieldTypeList"
                  :key="item.value"
                  >{{ item.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <div v-show="formData.FieldType === 'Customize'">
              <el-form-item
                label="客户"
                prop="customer"
                :label-width="LabelWidth"
              >
                <el-select
                  v-model="formData.customer"
                  filterable
                  multiple
                  placeholder="请选择或者搜索"
                  style="width: 440px"
                >
                  <el-option
                    v-for="item in customerFields"
                    :key="item.APIName"
                    :label="item.Name"
                    :value="item.APIName"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="车辆" prop="car" :label-width="LabelWidth">
                <el-select
                  v-model="formData.car"
                  filterable
                  multiple
                  placeholder="请选择或者搜索"
                  style="width: 440px"
                >
                  <el-option
                    v-for="item in carFields"
                    :key="item.APIName"
                    :label="item.Name"
                    :value="item.APIName"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="关系"
                prop="relation"
                :label-width="LabelWidth"
              >
                <el-select
                  v-model="formData.relation"
                  filterable
                  multiple
                  placeholder="请选择或者搜索"
                  style="width: 440px"
                >
                  <el-option
                    v-for="item in relationFields"
                    :key="item.APIName"
                    :label="item.Name"
                    :value="item.APIName"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </cond-card>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { Message } from "element-ui";
import condCard from "@/components/cond-card";
import { apiExport } from "@/api/group";
import { mapGetters } from "vuex";
export default {
  components: {
    condCard,
  },
  props: {
    exist: {
      type: Boolean,
      default: false,
    },
    saveType: {
      type: String,
      default: () => "add",
    },
    detail: {
      type: Object,
      default: () => {return {}},
    },
  },

  data() {
    return {
      saving: false,
      direction: "rtl",
      LabelWidth: "130px",
      formData: {
        FieldType: "All",
        customer: [],
        car: [],
        relation: [],
      },
      formRule: {
        FieldType: {
          required: true,
          message: "请选择字段设置",
          trigger: "blur",
        },
      },
      disabled: false,
      fieldTypeList: [
        // {
        //   label: "全部",
        //   value: "All",
        // },
        {
          label: "选择字段",
          value: "Customize",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      customerFields: "common/customerFields",
      carFields: "common/carFields",
      relationFields: "common/relationFields",
    }),
  },
  watch: {
    exist(newVal) {
      if (newVal !== true) {
        this.resetForm();
      }
    },
  },
  methods: {
    onFieldSet(val) {
      //去掉报错信息
      val && this.$el.querySelector(".el-form-item__error")
        ? this.$el.querySelector(".el-form-item__error").remove()
        : "";
    },
    handleClose() {
      this.$emit("update:exist", false);
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm();
        }
      });
    },
    submitForm() {
      const FieldType = this.formData.FieldType;
      const customer = this.formData.customer;
      const car = this.formData.car;
      const relation = this.formData.relation;
      if (FieldType === "Customize") {
        // 判断是否至少输入一个字段的值
        if (!customer.length && !car.length && !relation.length) {
          Message.error("请先选择客户、车辆、关系中的某一个值");
          return;
        } else {
          let Fields = [];
          if (customer.length) {
            Fields.push({
              ObjectAPIName: "Customer",
              Columns: customer,
            });
          }
          if (car.length) {
            Fields.push({
              ObjectAPIName: "Car",
              Columns: car,
            });
          }
          if (relation.length) {
            Fields.push({
              ObjectAPIName: "CustomerCarRelation",
              Columns: relation,
            });
          }

          let params = { FieldType, Fields };
          this.Export(params);
        }
      } else {
        this.Export({ FieldType });
      }
    },
    Export(params) {
      this.saving = true;
      apiExport(this.detail._id, params)
        .then(() => {
          this.$message.success("客户分组导出成功，请到下载中心查看。");
          this.$emit("success");
        })
        .finally(() => {
          this.saving = false;
        });
    },
    resetForm() {
      this.formData = {
        FieldType: "All",
        customer: [],
        car: [],
        relation: [],
      };
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
.edit-drawer {
  /deep/ .el-drawer {
    width: 72% !important;
    background: #f6f7f8;

    .el-drawer__header {
      margin-bottom: 16px;
      padding: 0;
    }

    .el-drawer__body {
      padding: 0 30px;
      overflow: auto;
    }
  }

  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 30px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);

    .left {
      font-size: 24px;
      line-height: 32px;

      i {
        margin-right: 22px;
        cursor: pointer;
      }
    }
  }

  .step-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 14px;

    .step-item {
      display: flex;
      align-items: center;
      width: 230px;
      padding: 10px 12px 10px 20px;
      background: #fff;
      border: 1px solid #b7b6b9;
      border-radius: 6px;

      > i {
        width: 24px;
        margin-right: 12px;
        font-size: 24px;
        color: #747378;
      }

      .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
      }

      .desc {
        font-size: 12px;
        line-height: 19px;
      }
    }

    > i {
      font-size: 16px;
      font-weight: bold;
      margin: 0 22px;
    }
  }
}
</style>
