<template>
  <div class="table-wrapper">
    <img
      @click="showQuery = !showQuery"
      class="showQuery"
      src="@/assets/img/query-more.png"
      alt=""
    />
    <el-tabs v-model="activeName">
      <el-tab-pane v-if="pageName === 'employee'" label="员工执行明细" name="员工执行明细">
        <report-list
          ref="reportList"
          :showQuery.sync="showQuery"
          :id="planId"
        ></report-list>
      </el-tab-pane>
      <el-tab-pane v-if="pageName === 'customer'" label="客户触达明细" name="客户触达明细">
        <chuda-list
          ref="chudaList"
          :showQuery.sync="showQuery"
          :id="planId"
          :isComWx="isComWx"
        ></chuda-list>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import reportList from "./report-list.vue";
import chudaList from "./chuda-list.vue";

export default {
  components: { reportList, chudaList },
  props: {
    pageName: {
      type: String,
      default: 'employee'
    },
    isComWx: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      planId: this.$route.params.id,
      activeName: this.pageName === 'employee' ? "员工执行明细" : "客户触达明细",
      showQuery: false,
    };
  },
  methods: {
    refresh() {
      this.$refs.reportList.refresh();
      this.$refs.chudaList.refresh();
    },
  },
};
</script>

<style lang="less" scoped>
.table-wrapper {
  padding: 24px;
  padding-bottom: 34px;
  background: #ffffff;
  position: relative;
  /* M - 04 dp - card */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.04),
    0px 3px 6px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;

  /deep/ .el-tabs__header {
    margin-bottom: 0;
  }
  /deep/ .el-tabs__item {
    // font-size: 20px;
    // line-height: 32px;
    font-weight: 500;
  }
  .showQuery {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
    z-index: 3;
  }
}
</style>
