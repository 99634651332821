<template>
  <div class="table-wrapper">
    <div class="table-title">关联的用户信息</div>
    <el-table :data="tableData" v-loading="loading" class="table-customer">
      <el-table-column prop="CustomerName" label="客户名称" width="320">
        <template slot-scope="scope">
          <router-link :to="`/customer/info/${scope.row.Id}`" class="g-link">
            <i
              class="iconfont icon-customer"
              style="margin-right: 6px; vertical-align: bottom"
            ></i>
            <span>{{ scope.row.CustomerName }}</span>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="CustomerSex" label="性别" width="168">
      </el-table-column>
      <el-table-column prop="CustomerAge" label="年龄" width="168">
      </el-table-column>
      <!-- <el-table-column prop="address" label="行驶公里数" width="125">
      </el-table-column>
      <el-table-column prop="address" label="状态" width="125">
      </el-table-column> -->
      <el-table-column prop="CustomerCarRelation" label="人车关系">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less" scoped>
.table-wrapper {
  margin-bottom: 20px;
  padding: 24px;
  padding-bottom: 34px;
  background: #ffffff;
  /* M - 04 dp - card */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.04),
    0px 3px 6px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  .table-title {
    margin-bottom: 18px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
  }
  .pagination {
    text-align: right;
    padding: 20px 0 42px 0;
  }
}
</style>
