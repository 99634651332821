<template>
  <div>
    <el-row :gutter="16">
      <el-col v-for="item in list" :key="item._id" :span="8 * item.ColumnSpan">
        <div class="card">
          <div class="header">
            <span class="title">{{ item.Title }}</span>
          </div>
          <div class="content">
            <div
              :id="item._id"
              style="height: 240px"
              v-if="item.ChartType === 'gauge'"
              v-loading="item.loading"
            ></div>
            <!-- 活跃度 进度 -->
            <div
              v-if="item.ChartType === 'bar'"
              class="progress"
              v-loading="item.loading"
            >
              <div
                class="progress-item"
                v-for="i in dataMap[item._id].data"
                :key="i.key"
              >
                <div class="progress-label">{{ i.key }}</div>
                <div class="progress-val">
                  <el-progress
                    :percentage="i.value"
                    :show-text="false"
                    class="progress-content"
                  ></el-progress>
                  <div class="progress-text">
                    <!-- <div>{{ item.XAxis }}</div> -->
                    <div></div>
                    <div>{{ i.value }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 雷达图 -->
            <ve-radar
              :data="dataMap[item._id]"
              v-if="item.ChartType === 'radar'"
              :settings="radarSetting"
              :extend="item.extend"
              :legend-visible="false"
              v-loading="item.loading"
              height="240px"
            ></ve-radar>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  apiGetCustomerChartList,
  apiGetCustomerChartDetail,
  apiGetCarChartList,
  apiGetCarChartDetail,
} from "@/api/group";
import { cloneDeep } from "lodash";
const defaultColor = [
  "#36A3FC",
  "#44DCEE",
  "#8CC270",
  "#F2B540",
  "#EB8F47",
  "#E44E93",
  "#B27AB8",
  "#999999",
];
export default {
  props: {
    type: {
      type: String,
      default: "Customer",
    },
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      list: [],
      radarSetting: {},
      dataMap: {},
      radarExtend: {
        color: defaultColor,
        radar: {
          indicator: [
            { max: 6500 },
            { max: 16000 },
            { max: 30000 },
            { max: 38000 },
            { max: 52000 },
          ],
        },
      },
    };
  },
  methods: {
    getChartList() {
      let promise;
      if (this.type === "Customer") {
        promise = apiGetCustomerChartList();
      } else {
        promise = apiGetCarChartList();
      }
      promise.then((res) => {
        if (Array.isArray(res)) {
          res.forEach((item) => {
            item.loading = true;
            this.$set(this.dataMap, item._id, {
              columns: [],
              rows: [],
              setting: {},
              extend: {},
            });
          });
          this.list = res;
          this.getChartSerial();
        }
      });
    },
    // 循环获取
    getChartSerial() {
      let index = 0;
      const fetch = () => {
        if (index >= this.list.length) {
          return;
        }
        const item = this.list[index++];
        this.getChartDetail(item)
          .then(() => {
            fetch();
          })
          .finally(() => {
            // 不间断
            fetch();
            item.loading = false;
          });
      };
      fetch();
    },
    // 获取某个图表的详情
    getChartDetail(item) {
      let promise;
      if (this.type === "Customer") {
        promise = apiGetCustomerChartDetail(item._id, this.id);
      } else {
        promise = apiGetCarChartDetail(item._id, this.id);
      }
      return promise.then((res) => {
        try {
          let columns = [];
          let rows = res.Data || [];

          if (res.ChartType === "radar") {
            // 雷达图需要特殊处理
            columns.push(res.XAxis);
            const rowItem = {
              [res.XAxis]: res.Title,
            };
            let max = 0;
            let indicator = [];
            res.Data.forEach((item) => {
              columns.push(item[res.XAxis]);
              rowItem[item[res.XAxis]] = item[res.YAxis];
              max = Math.max(max, item[res.YAxis]);
              indicator.push({
                name: item[res.XAxis],
              });
            });
            indicator.forEach((item) => {
              item.max = max;
            });
            item.extend = cloneDeep(this.radarExtend);
            item.extend.radar.indicator = indicator;
            rows = [rowItem];
          } else if (res.ChartType === "gauge") {
            let detailShow = true;
            let value = res.Data[0][res.YAxis];
            let Max = res.Max;
            let Min = res.Min;
            let valueText = res.Data[0][res.YAxis];
            let ValueOptions = null;
            if (this.type === "Car") {
              ValueOptions = res.ValueOptions.split(",");
              if (!value) {
                value = ValueOptions[0];
                detailShow = false;
              }

              value =
                (ValueOptions.indexOf(value) + 1) / ValueOptions.length -
                1 / ValueOptions.length / 2;
              Max = 1;
              Min = 0;
            }

            this.initGauge(
              item._id,
              value,
              Max,
              Min,
              valueText,
              ValueOptions,
              res.YAxis,
              detailShow
            );
            return;
          } else {
            columns = Object.keys(res.Data[0]).filter(
              (key) => key !== res.XAxis
            );
            columns.unshift(res.XAxis);
          }

          // 将X轴的值都转成字符串，否则显示会有问题
          rows.forEach((row) => {
            row[res.XAxis] = "" + row[res.XAxis];
          });

          // 条形bar
          if (res.ChartType === "bar") {
            let barData = res.Data.map((item) => {
              return {
                key: item[res.XAxis],
                value: Number(item[res.YAxis]),
              };
            });
            this.dataMap[item._id] = {
              data: barData,
              XAxis: res.XAxis,
            };
          } else {
            this.dataMap[item._id] = {
              columns,
              rows,
              setting: this[res.ChartType + "Setting"],
              extend: this[res.ChartType + "Extend"],
            };
          }
          console.log(this.dataMap);
        } catch (error) {
          console.log(error);
        }
      });
    },
    // 仪表盘
    initGauge(id, value, Max, Min, valueText, ValueOptions, YAxis, detailShow) {
      const echarts = window.echarts;
      var chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom);
      var option;
      let axisLabel = null;
      let splitNumber = 10;
      let axisLine = null;
      if (this.type === "Car") {
        axisLabel = {
          color: "#464646",
          fontSize: 14,
          distance: 10,
          formatter: function (value) {
            let res = "";
            // 每格刻度的大小
            let setp = 1 / ValueOptions.length;
            // 当前刻度值匹配数组每个文字的位置值
            ValueOptions.map((item, index) => {
              if (
                (setp * (index + 1) - setp / 2).toFixed(4) === value.toFixed(4)
              ) {
                res = item;
              }
            });
            return res;
          },
        };
        axisLine = {
          lineStyle: {
            width: 4,
            // color: [
            //   [0.25, "#FF6E76"],
            //   [0.75, "#FDDD60"],
            //   [1, "#58D9F9"],
            // ],
          },
        };
        splitNumber = ValueOptions.length * 2;
      } else {
        axisLabel = {
          fontSize: 8,
          distance: 6,
        };
        axisLine = {
          lineStyle: {
            width: 4,
          },
        };
      }
      option = {
        tooltip: {
          show: detailShow,
          formatter: "{a} <br/>{b} : {c}%",
        },
        series: [
          {
            name: "Pressure",
            type: "gauge",
            splitNumber,
            min: Min,
            max: Max,
            center: ["50%", "50%"],
            radius: "100%",
            progress: {
              // 当前进度
              show: this.type === "Customer",
              width: 4,
            },
            detail: {
              // 值
              valueAnimation: true,
              formatter: valueText,
              fontSize: 18,
              show: detailShow,
            },
            axisLine,
            splitLine: {
              // 分隔线
              distance: 4,
              length: 6,
              lineStyle: {
                width: 2,
              },
            },
            axisLabel,
            axisTick: {
              // 刻度样式
              distance: 4,
              length: 4,
            },
            pointer: {
              width: 4,
            },
            data: [
              {
                title: {
                  fontSize: 10,
                },
                value: value,
                name: YAxis,
              },
            ],
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
  mounted() {
    this.getChartList();
  },
};
</script>

<style lang="less" scoped>
.card {
  margin-bottom: 22px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 24px 36px rgba(0, 0, 0, 0.04),
    0px 16px 32px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  .header {
    margin-bottom: 14px;
    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
    }
  }
  .progress {
    height: 240px;
    overflow-y: auto;
    .progress-item {
      display: flex;
      margin-bottom: 20px;
      padding-right: 20px;
      .progress-label {
        font-size: 14px;
        color: #2c3e50;
        width: 90px;
        text-align: left;
      }
      .progress-val {
        flex: 1;
      }
      .progress-content {
        margin: 6px 0;
      }
      .progress-text {
        color: #2c3e50;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
