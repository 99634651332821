<template>
  <div class="edit-drawer">
    <el-drawer
      :visible.sync="exist"
      :direction="direction"
      :before-close="handleClose"
      :show-close="false"
      :wrapperClosable="false"
    >
      <div slot="title" class="title-wrap">
        <div class="left">
          <i class="el-icon-close" @click="handleClose"></i>
          <span>{{
            saveType === "add"
              ? "新建客户分群"
              : saveType === "copy"
              ? "复制客户分群"
              : saveType === "edit"
              ? "编辑客户分群"
              : "查看客户分群"
          }}</span>
        </div>
        <div class="right">
          <el-button type="text" @click="handleClose">取消</el-button>
          <!-- <el-button
            @click="showPre = true"
            round
            style="background: #4d4b58; color: #fff"
            >预览分群概貌</el-button
          > -->
          <el-button
            type="primary"
            round
            @click="handleSave"
            :loading="saving"
            :disabled="disabled"
            >保存</el-button
          >
        </div>
      </div>
      <div class="drawer-content">
        <el-form
          :model="formData"
          :rules="formRule"
          ref="form"
          class="edit-form"
          size="small"
          :disabled="disabled"
        >
          <div class="step-list">
            <div class="step-item">
              <i class="iconfont icon-users"></i>
              <div>
                <div class="title">分群基本信息</div>
                <div class="desc">设置分群名称及数据更新方式</div>
              </div>
            </div>
            <i class="el-icon-arrow-right"></i>
            <div class="step-item">
              <i class="iconfont icon-group"></i>
              <div>
                <div class="title">分群条件</div>
                <div class="desc">定义分群条件和逻辑</div>
              </div>
            </div>
          </div>
          <cond-card title="分群基本信息">
            <el-form-item label="分群名称" prop="Name">
              <el-input
                v-model="formData.Name"
                placeholder="分群名称"
                style="width: 440px"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="是否公开" prop="IsPublic">
              <el-switch v-model="formData.IsPublic"> </el-switch>
            </el-form-item> -->
          </cond-card>
          <customer-filter
            ref="customerFilter"
            :exist="exist"
            :max-height="590"
            :setDefaultCusFilter="saveType === 'add'"
          ></customer-filter>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { apiGetGroupDetail, apiAddGroup, apiUpdateGroup } from "@/api/group";
import customerFilter from "@/components/customer-filter";
import condCard from "@/components/cond-card";
export default {
  components: {
    condCard,
    customerFilter,
  },
  props: {
    exist: {
      type: Boolean,
      default: false,
    },
    saveType: {
      type: String,
      default: () => "add",
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      saving: false,
      direction: "rtl",
      formData: {
        Name: "",
        IsPublic: false,
      },
      formRule: {
        Name: {
          required: true,
          message: "请输入分群名称",
          trigger: "blur",
        },
      },
      disabled: false,
    };
  },
  watch: {
    exist(newVal) {
      if (newVal === true) {
        this.getGroupDetail();
      } else {
        this.resetForm();
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit("update:exist", false);
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitForm();
        }
      });
    },
    submitForm() {
      if (this.$el.querySelectorAll(".err-msg").length > 0) {
        return;
      }
      const customerResult = this.$refs.customerFilter.getResult();
      if (customerResult === false) {
        return false;
      }
      const submit = () => {
        this.saving = true;
        const param = {
          ...this.formData,
          IsPublic: this.formData.IsPublic === true ? 1 : 0,
          ...customerResult,
        };

        let prom;
        if (this.saveType === "add" || this.saveType === "copy") {
          prom = apiAddGroup(param);
        } else {
          prom = apiUpdateGroup(this.detail._id, param);
        }
        prom
          .then((res) => {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.$emit("success", res);
            console.log(res);
          })
          .finally(() => {
            this.saving = false;
          });
      };
      if (customerResult.FilterGroups.length) {
        submit()
      } else {
        this.$confirm('您没有选择任何分群条件，确认创建全部客户的分群？', '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          submit()
        })
      }
    },
    resetForm() {
      this.formData = {
        Name: "",
        IsPublic: false,
      };
      this.$refs.customerFilter.resetForm();
    },
    getGroupDetail() {
      if (
        this.saveType === "edit" ||
        this.saveType === "copy" ||
        this.saveType === "show"
      ) {
        if (this.saveType === "show") {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
        apiGetGroupDetail(this.detail._id).then((res) => {
          const name = this.saveType === "copy" ? res.Name + " copy" : res.Name;
          Object.assign(this.formData, {
            Name: name,
            IsPublic: +res.IsPublic == 1 ? true : false,
          });

          const initData = {
            FilterConditionLogic: res.FilterConditionLogic,
            FilterConditionType: res.FilterConditionType,
          };
          res.FilterGroups.forEach((item) => {
            if (item.FilterGroup === "客户") {
              initData.condCustList = item.FilterItems;
            } else if (item.FilterGroup === "车辆") {
              initData.condCarList = item.FilterItems;
            } else if (
              item.FilterGroup === "集团卡券" ||
              item.FilterGroup === "集团会员卡券"
            ) {
              initData.condCliqueCardsAndVouchersList = item.FilterItems;
            } else if (
              //item.FilterGroup === "集团积分" ||
              item.FilterGroup === "连接渠道"
            ) {
              initData.condCliquePointsAndRechargesList = item.FilterItems;
            } else if (item.FilterGroup === "企业积分") {
              initData.condDealerPointsAndRechargesList = item.FilterItems;
            } else if (item.FilterGroup === "企业卡券") {
              initData.condDealerCardsAndVouchersList = item.FilterItems;
            } else if (item.FilterGroup === "关系") {
              initData.condRelaList = item.FilterItems;
            } else if (item.FilterGroup === "模型") {
              initData.modelFilterList = item.FilterItems || [];
              initData.modelFilterList.forEach((child) => {
                child.nowobject = item.ObjectAPIName;
              });
            } else if (item.FilterGroup === "ID上传") {
              initData.idFilterList = item.FilterItems || [];
            }
          });
          this.$refs.customerFilter.initForm(initData);
        });
      }
    },
  },
  created() {
    if (this.exist) {
      this.getGroupDetail();
    }
  },
};
</script>

<style lang="less" scoped>
.edit-drawer {
  /deep/ .el-drawer {
    width: 72% !important;
    background: #f6f7f8;
    .el-drawer__header {
      margin-bottom: 16px;
      padding: 0;
    }
    .el-drawer__body {
      padding: 0 30px;
      overflow: auto;
    }
  }
  /deep/ .el-form-item__error {
    padding-left: 70px;
  }
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 30px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
    .left {
      font-size: 24px;
      line-height: 32px;
      i {
        margin-right: 22px;
        cursor: pointer;
      }
    }
  }

  .step-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 14px;
    .step-item {
      display: flex;
      align-items: center;
      width: 230px;
      padding: 10px 12px 10px 20px;
      background: #fff;
      border: 1px solid #b7b6b9;
      border-radius: 6px;
      > i {
        width: 24px;
        margin-right: 12px;
        font-size: 24px;
        color: #747378;
      }
      .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
      }
      .desc {
        font-size: 12px;
        line-height: 19px;
      }
    }
    > i {
      font-size: 16px;
      font-weight: bold;
      margin: 0 22px;
    }
  }
}
</style>
