<template>
  <div class="page-customer">
    <content-header :title="title" :breadList="['客户中心', '分群列表']">
      <el-button
        slot="tools"
        type="primary"
        round
        @click="
          existDrawer = true;
          editType = 'add';
        "
        v-if="
          menuPermission &&
          menuPermission.客户中心 &&
          menuPermission.客户中心.create
        "
      >
        <i class="el-icon-plus"></i>
        <span>创建客户分群</span>
      </el-button>
    </content-header>
    <div class="content-wrapper">
      <!-- 筛选条件 -->
      <div class="query-form" v-if="showQuery">
        <i class="el-icon-close" @click="showQuery = false"></i>
        <el-form
          :inline="true"
          v-if="showQuery"
          label-width="100px"
          :rules="formRule"
          ref="ruleForm"
          :model="formData"
        >
          <el-form-item label="客户分组名称:">
            <el-input
              placeholder="客户分组名称"
              v-model="formData.Name"
              style="width: 200px"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="创建者:">
            <el-select
              v-model="formData.CreatedById"
              style="width: 200px"
              clearable
              filterable
              :loading="userLoading"
            >
              <el-option
                :value="item._id"
                :label="item.Name"
                v-for="item in userList"
                :key="item._id"
              ></el-option>
            </el-select>
          </el-form-item>
          <div style="display: inline-block">
            <el-form-item label="人数介于:" prop="RecordCountLt">
              <el-input-number
                placeholder="最小值"
                v-model="formData.RecordCountLt"
                style="width: 120px"
                :min="0"
                clearable
                :controls="false"
                :precision="0"
              />
            </el-form-item>
            <div
              style="margin-left: 5px;margin-right: 8px;margin-top: 10px;display: inline-block;"
            >
              至
            </div>
            <el-form-item label="" prop="RecordCountRt">
              <el-input-number
                placeholder="最大值"
                v-model="formData.RecordCountRt"
                style="width: 120px"
                :min="0"
                clearable
                :controls="false"
                :precision="0"
              />
            </el-form-item>
          </div>
          <el-form-item label="创建时间介于:">
            <el-date-picker
              v-model="formData.StartTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 100%"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>

          <el-button size="medium" type="info" @click="handleQuery" round
            >应用</el-button
          >
        </el-form>
      </div>

      <div class="headOperation">
        <img
          src="../../assets/img/refresh.png"
          class="refresh"
          @click="getGroups"
        />
        <img
          @click="showQuery = !showQuery"
          class="showQuery"
          src="@/assets/img/query-more.png"
          alt=""
        />
        <img
          src="../../assets/img/download.png"
          class="download"
          @click="handleDownload"
        />
      </div>
      <el-table :data="tableData" v-loading="loading" class="table-customer">
        <el-table-column
          prop="Name"
          label="客户分组名称"
          style="overflow: visible"
        >
          <template slot-scope="scope">
            <router-link
              :to="`/group/detail/${scope.row._id}`"
              class="g-link auto-cut"
              style="display: flex"
            >
              <div class="icon-wrap">
                <div class="first-char">
                  {{ scope.row.Name.substr(0, 1) }}
                </div>
                <div class="icon-user">
                  <i class="el-icon-s-custom"></i>
                </div>
              </div>
              <span class="group-name" :title="scope.row.Name">
                {{ scope.row.Name }}
              </span>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="RecordCount" label="人数"> </el-table-column>
        <el-table-column prop="CreatedByUser" label="创建者">
          <template slot-scope="scope">
            <div style="display: flex">
              <div class="icon-wrap">
                <div class="first-char-user">
                  {{ scope.row.CreatedByUser.substr(0, 1) }}
                </div>
              </div>
              <span class="user-name" :title="scope.row.CreatedByUser">
                {{ scope.row.CreatedByUser }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="CreatedDate" label="创建时间"> </el-table-column>
        <!-- <el-table-column prop="IsPublic" label="是否公开">
        <el-table-column prop="CreatedDate" label="创建时间"> </el-table-column>
        <el-table-column prop="IsPublic" label="是否公开">
          <template slot-scope="scope">
            <div v-if="+scope.row.IsPublic == 1">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="margin-right: 4px; vertical-align: bottom"
              >
                <mask
                  id="mask0"
                  mask-type="alpha"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="4"
                  width="20"
                  height="13"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.15833 7.16669C9.15833 8.55002 8.05 9.66669 6.66667 9.66669C5.28333 9.66669 4.16667 8.55002 4.16667 7.16669C4.16667 5.78335 5.28333 4.66669 6.66667 4.66669C8.05 4.66669 9.15833 5.78335 9.15833 7.16669ZM15.825 7.16669C15.825 8.55002 14.7167 9.66669 13.3333 9.66669C11.95 9.66669 10.8333 8.55002 10.8333 7.16669C10.8333 5.78335 11.95 4.66669 13.3333 4.66669C14.7167 4.66669 15.825 5.78335 15.825 7.16669ZM6.66667 11.3334C4.725 11.3334 0.833332 12.3084 0.833332 14.25V15.5C0.833332 15.9584 1.20833 16.3334 1.66667 16.3334H11.6667C12.125 16.3334 12.5 15.9584 12.5 15.5V14.25C12.5 12.3084 8.60833 11.3334 6.66667 11.3334ZM12.525 11.375C12.8167 11.35 13.0917 11.3334 13.3334 11.3334C15.275 11.3334 19.1667 12.3084 19.1667 14.25V15.5C19.1667 15.9584 18.7917 16.3334 18.3334 16.3334H14.0167C14.1084 16.075 14.1667 15.7917 14.1667 15.5V14.25C14.1667 13.025 13.5084 12.1 12.5584 11.4084C12.5558 11.4058 12.5533 11.4025 12.5506 11.399C12.5442 11.3907 12.5367 11.3808 12.525 11.375Z"
                    fill="black"
                  />
                </mask>
                <g mask="url(#mask0)">
                  <rect y="0.5" width="20" height="20" fill="#B7B6B9" />
                </g>
              </svg>

              <span>公开</span>
            </div>
            <div v-else>未公开</div>
          </template>
        </el-table-column> -->
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <div class="tool-wrap">
              <el-button type="text" @click="handleEdit(scope.row)">
                <i class="iconfont icon-edit"></i>
              </el-button>
              <el-dropdown @command="handleCommand($event, scope.row)">
                <span class="el-dropdown-link">
                  <i class="iconfont icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    command="upload"
                    :disabled="scope.row.CreatedById !== userInfo._id"
                    ><i class="iconfont icon-upload"></i>导出</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="copy"
                    v-if="
                      menuPermission &&
                      menuPermission.客户中心 &&
                      menuPermission.客户中心.create
                    "
                    ><i class="iconfont icon-copy"></i>复制</el-dropdown-item
                  >
                  <el-dropdown-item command="detail"
                    ><i class="iconfont icon-user-doublekx"></i
                    >画像</el-dropdown-item
                  >
                  <el-dropdown-item command="delete"
                    ><i class="iconfont icon-delete"></i>删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-button type="text" @click="refreshGroupNumber(scope.row)" v-if="!['计算中...', '文件上传中...', '上传失败'].includes(scope.row.RecordCount)">
                <icon-svg name="recount" className="recount-icon"></icon-svg>
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        layout="prev, pager, next"
        :total="pagination.totalCount"
        :current-page="pagination.currentPage"
        :page-size="pagination.pageSize"
        @current-change="handlePage"
      >
      </el-pagination>
    </div>
    <edit-drawer
      v-if="existDrawer"
      :exist.sync="existDrawer"
      :saveType="editType"
      :detail="curGroup"
      @success="handleSaved"
    ></edit-drawer>
    <output-drawer
      v-if="outputDrawer"
      :exist.sync="outputDrawer"
      :detail="curGroup"
      @success="handleOutputed"
    ></output-drawer>
    <down-drawer v-if="downDrawer" :exist.sync="downDrawer"></down-drawer>
    <el-dialog title="删除确认" :visible.sync="delConfirmShow" width="30%">
      <span>确定删除该分组吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delConfirmShow = false" type="text">取 消</el-button>
        <el-button type="primary" @click="handleDelete" round>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ContentHeader from "@/views/layout/content-header";
import EditDrawer from "./components/edit-drawer";
import OutputDrawer from "./components/output-drawer";
import DownDrawer from "./components/down-drawer";
import { apiGetGroups, apiDeleteGroupDetail, apiRefreshGroupNumber } from "@/api/group";
import { apiGetCustomerPermission } from "@/api/auth";
import { apiGetUserList } from "@/api/auth";

export default {
  components: {
    ContentHeader,
    EditDrawer,
    OutputDrawer,
    DownDrawer,
  },
  data() {
    const validateRecordCountLt = (rule, value, callback) => {
      console.log(rule, value);
      if (typeof value !== "undefined") {
        if (typeof this.formData.RecordCountRt !== "undefined") {
          this.$refs.ruleForm.validateField("RecordCountRt");
          callback();
        } else {
          callback();
        }
      } else {
        // 没有填值
        if (typeof this.formData.RecordCountRt !== "undefined") {
          // 如果右边值填了，左边必须填。
          callback(new Error("请输入小于右边的整数"));
        } else {
          callback();
        }
      }
    };

    const validateRecordCountRt = (rule, value, callback) => {
      console.log(rule, value);
      if (typeof value !== "undefined") {
        if (value <= this.formData.RecordCountLt) {
          callback(new Error("右边整数需比左边大"));
        } else {
          callback();
        }
      } else {
        // 没有填值
        if (typeof this.formData.RecordCountLt !== "undefined") {
          // 如果左边值填了，右边必须填。
          callback(new Error("请输入大于左边的整数"));
        } else {
          callback();
        }
      }
    };

    return {
      title: this.$route.meta.title,
      loading: false,
      tableData: [],
      existDrawer: false,
      delConfirmShow: false,
      editType: "add",
      curGroup: {},
      pagination: {
        pageSize: 10,
        totalCount: 0,
        currentPage: 1,
      },
      outputDrawer: false,
      downDrawer: false,
      showQuery: false,
      userLoading: true,
      userList: [],
      formData: {
        Name: "",
        CreatedById: "",
        StartTime: "",
        RecordCountLt: undefined,
        RecordCountRt: undefined,
      },
      formRule: {
        RecordCountLt: [
          {
            validator: validateRecordCountLt,
            trigger: "blur",
          },
        ],
        RecordCountRt: [{ validator: validateRecordCountRt, trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapGetters({
      menuPermission: "common/menuPermission",
      userInfo: "common/userInfo",
    }),
  },
  watch: {
    $route() {
      const query = this.$route.query;
      if (query.status === "add") {
        this.existDrawer = true;
        this.editType = "add";
      }
    },
  },
  methods: {
    getGroups() {
      const getList = () => {
        this.loading = true;
        const { pageSize, currentPage } = this.pagination;
        const formData = JSON.parse(JSON.stringify(this.formData));
        const param = {
          offset: pageSize * (currentPage - 1),
          limit: pageSize,
          ...formData,
        };
        if (formData.StartTime) {
          param.CreateTimeLt = formData.StartTime[0] + ' 00:00:00';
          param.CreateTimeRt = formData.StartTime[1] + ' 23:59:59';
          delete param.StartTime;
        }

        apiGetGroups(param)
          .then((res) => {
            res.customerGroups.forEach((res) => {
              if (res.RecordCount == -1) {
                res.RecordCount = "计算中...";
              } else if (res.RecordCount == -2) {
                res.RecordCount = "计算错误";
              } else if (res.RecordCount == -3) {
                res.RecordCount = "文件上传中...";
              } else if (res.RecordCount == -4) {
                res.RecordCount = "上传失败";
              }
            });
            this.tableData = res.customerGroups;
            this.pagination.totalCount = res.meta.total_count;
          })
          .finally(() => {
            this.loading = false;
          });
      };

      if (this.$refs["ruleForm"]) {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            getList();
          }
        });
      } else {
        getList();
      }
    },
    getUserList() {
      this.userLoading = true;
      const param = {
        offset: 0,
        limit: 1000,
      };
      apiGetUserList(param)
        .then((res) => {
          this.userList = res.users;
        })
        .finally(() => {
          this.userLoading = false;
        });
    },
    handlePage(index) {
      this.pagination.currentPage = index;
      this.getGroups();
    },
    handleEdit(detail) {
      // 请求编辑权限
      apiGetCustomerPermission(detail._id, {
        target: "edit",
      }).then((res) => {
        if (res.PermissionEdit) {
          this.curGroup = detail;
          this.editType = "edit";
          this.existDrawer = true;
        } else {
          //this.$message.error("无编辑权限");
          this.curGroup = detail;
          this.editType = "show";
          this.existDrawer = true;
        }
      });
    },
    handleCommand(cmd, detail) {
      switch (cmd) {
        case "upload":
          this.upload(detail);
          break;
        case "detail":
          this.$router.push(`/group/detail/${detail._id}`);
          break;
        case "delete":
          // 请求删除权限
          apiGetCustomerPermission(detail._id, {
            target: "delete",
          }).then((res) => {
            if (res.PermissionDelete) {
              this.curGroup = detail;
              this.delConfirmShow = true;
            } else {
              this.$message.error("无删除权限");
            }
          });
          break;
        case "copy":
          this.curGroup = detail;
          this.editType = "copy";
          this.existDrawer = true;
          break;
      }
    },
    upload(detail) {
      if (
        this.menuPermission &&
        this.menuPermission.客户中心 &&
        this.menuPermission.客户中心.exportPermission
      ) {
        if (detail.CreatedById === this.userInfo._id) {
          if (detail.RecordCount > 100000) {
            this.$message.warning(
              "您下载的数据量过大无法在线导出，建议您联系管理员。"
            );
          } else {
            this.curGroup = detail;
            this.outputDrawer = true;
          }
        }
      } else {
        this.$message.error("无导出权限");
      }
    },
    handleSaved() {
      this.existDrawer = false;
      this.getGroups();
    },
    handleOutputed() {
      this.outputDrawer = false;
    },
    handleDelete() {
      apiDeleteGroupDetail(this.curGroup._id).then(() => {
        this.delConfirmShow = false;
        this.getGroups();
      });
    },
    handleDownload() {
      if (
        this.menuPermission &&
        this.menuPermission.客户中心 &&
        this.menuPermission.客户中心.exportPermission
      ) {
        this.downDrawer = true;
      } else {
        this.$message.error("无导出权限");
      }
    },
    handleQuery() {
      this.handlePage(1);
    },
    refreshGroupNumber(row) {
      apiRefreshGroupNumber(row._id).then((res) => {
        row.RecordCount = "计算中...";
      });
    },
  },
  mounted() {
    this.getGroups();
    this.getUserList();
    const query = this.$route.query;
    if (query.status === "add") {
      this.existDrawer = true;
      this.editType = "add";
    }
  },
};
</script>

<style lang="less" scoped>
.page-customer {
  .recount-icon {
    font-size: 20px;
    margin-right: 20px;
    color: #9a9a9e;
    font-style: normal;
    vertical-align: middle;
  }
  .query-form {
    background: #f6f7f8;
    border-radius: 6px;
    position: relative;
    padding: 30px 90px 6px 16px;
    margin: 0 0 20px 0;
    .el-icon-close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    /deep/ .el-button--info {
      position: absolute;
      right: 10px;
      bottom: 16px;
      background: #4d4b58;
    }
  }

  .tool-wrap {
    /deep/ i {
      font-size: 20px;
      margin-right: 20px;
      color: #9a9a9e;
    }
  }
  .icon-wrap {
    position: absolute;
    top: 50%;
    margin-top: -18px;
    .first-char,
    .first-char-user,
    .icon-user {
      position: absolute;
      border-radius: 50%;
      text-align: center;
    }
    .first-char,
    .first-char-user {
      width: 37px;
      height: 37px;
      line-height: 34px;
      left: 0;
      border: 1px solid #ffffff;
      background: #747378;
      font-size: 16;
      color: #fff;
      z-index: 1;
    }
    .icon-user {
      width: 36px;
      height: 36px;
      line-height: 36px;
      top: 1px;
      left: 15px;
      background: #e2e6e9;
      i {
        font-size: 18px;
        color: #4d4b58;
      }
    }
  }
  .group-name {
    margin-left: 63px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    flex: 1;
  }
  .user-name {
    margin-left: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    flex: 1;
  }
  .table-customer {
    /deep/.el-table__row:nth-child(3n + 1) {
      .first-char-user {
        background: #0483ec;
      }
    }
    /deep/.el-table__row:nth-child(3n + 2) {
      .first-char-user {
        background: #44c5b8;
      }
    }
    /deep/.el-table__row:nth-child(3n + 3) {
      .first-char-user {
        background: #f09c0a;
      }
    }
  }
  .headOperation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .download {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
    .showQuery {
      cursor: pointer;
    }
    .refresh {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }
}
</style>
