<template>
  <div class="page-car-info">
    <content-header
      :title="detail.Vin"
      :breadList="['客户中心', '分群画像', '客户信息', '车辆信息']"
    >
    </content-header>

    <life-cycle
      :type="'CarJourney'"
      :currentJourney="detail.CarJourney"
      style="display: none"
    ></life-cycle>
    <customer-chart :id="id" :type="'Car'"></customer-chart>
    <div class="base-info">
      <div class="header-img" v-if="!loading">
        <img :src="detail.Avatar" alt="" v-if="detail.Avatar" />
        <img v-else src="../../assets/img/car.jpg" />
      </div>
      <div class="detail">
        <el-row>
          <el-col :span="6">
            <div class="title">基本信息</div>
          </el-col>
          <el-col :span="6">
            <div class="label">车牌</div>
            <div class="value">{{ detail.License }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">品牌</div>
            <div class="value">{{ detail.CarBrand }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">车系</div>
            <div class="value">{{ detail.CarSeries }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="title"></div>
          </el-col>
          <el-col :span="6">
            <div class="label">车龄（年）</div>
            <div class="value">{{ detail.CarAge }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">当前里程</div>
            <div class="value">{{ detail.MilesCurrent }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">售后进站总次数</div>
            <div class="value">{{ detail.TotalNumAfterSales }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="title"></div>
          </el-col>
          <el-col :span="6">
            <div class="label">售后进站消费总金额</div>
            <div class="value">{{ detail.TotalPriceAfterSales }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">最近一次进站时间</div>
            <div class="value">{{ detail.LastGoInStore }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">最近一次进站企业</div>
            <div class="value">{{ detail.DateLast1tEnterprise }}</div>
          </el-col>
        </el-row>
        <el-row style="border-bottom: 1px solid #dddddf">
          <el-col :span="6">
            <div class="title"></div>
          </el-col>
          <el-col :span="6">
            <div class="label">是否已经流失</div>
            <div class="value">{{ detail.CustomerLoss }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">流失风险</div>
            <div class="value">{{ detail.CarChurnRisk }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">增换购意向</div>
            <div class="value">{{ detail.CarRebuyPurpose }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="title">更多信息</div>
          </el-col>
          <el-col :span="6">
            <div class="label">最近一年进站总次数</div>
            <div class="value">{{ detail.TotalNumLast1yAfterSales }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">最近一年进站消费总金额</div>
            <div class="value">{{ detail.TotalPriceLast1yAfterSales }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">最近一次保养时间</div>
            <div class="value">{{ detail.LastMaintenance }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="title"></div>
          </el-col>
          <el-col :span="6">
            <div class="label">预计下次保养时间</div>
            <div class="value">{{ detail.NextMaintenance }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">延保到期时间</div>
            <div class="value">{{ detail.ExtendedInsuranceDueTime }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">最近一次保险购买时间</div>
            <div class="value">{{ detail.LastInsuranceBuyTime }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="title"></div>
          </el-col>
          <el-col :span="6">
            <div class="label">保险预计到期时间</div>
            <div class="value">{{ detail.InsuranceEstimateDueTime }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">最近一次机电小修进站时间</div>
            <div class="value">{{ detail.DateLast1tMaintenance }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">最近一次事故进站时间</div>
            <div class="value">{{ detail.DateLast1tAccident }}</div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- <car-behavior></car-behavior> -->
    <el-tabs v-model="activeName" class="tabs-wrapper" v-if="!loading">
      <el-tab-pane label="时间轴" name="first">
        <customerTimeline :id="detail.CarId" type="car" />
      </el-tab-pane>
      <el-tab-pane label="关联的用户信息" name="second">
        <customer-table
          :tableData="customersList"
          :customersLoading="customersLoading"
        ></customer-table>
      </el-tab-pane>
      <el-tab-pane label="企业会员" name="third">
        <carVip :id="detail.CarId" type="car" />
      </el-tab-pane>
      <el-tab-pane label="企业卡券" name="fourth">
        <carCoupon :id="detail.CarId" type="car" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ContentHeader from "@/views/layout/content-header";
import customerTable from "./components/customer-table";
// import carBehavior from "./components/car-behavior";
import { apiGetCarDetail, apiGetCarsCustomer } from "@/api/group";
import lifeCycle from "@/components/life-cycle";
import customerTimeline from "./components/customer-timeline.vue";
import customerChart from "./components/customer-chart.vue";
import carVip from "./components/car-vip.vue";
import carCoupon from "./components/car-coupon.vue";

export default {
  components: {
    ContentHeader,
    customerTable,
    // carBehavior,
    lifeCycle,
    customerTimeline,
    customerChart,
    carVip,
    carCoupon,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {
        Customers: [],
      },
      activeName: "first",
      loading: true,
      customersList: [],
      customersLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      carFields: "common/carFields",
    }),
  },
  methods: {
    getCarDetail() {
      this.loading = true;
      apiGetCarDetail(this.id).then((res) => {
        try {
          Object.keys(res).forEach((field) => {
            const find = this.carFields.find((item) => {
              return item.APIName === field;
            });
            if (find) {
              if (find.DataType === "Decimal") {
                if (res[field] && res[field].toLocaleString) {
                  res[field] = res[field].toLocaleString();
                }
              }
            }
          });
        } catch (error) {
          console.log(error);
        }
        this.detail = res;
        this.loading = false;
      });
    },
    getCustomers() {
      this.customersLoading = true;
      apiGetCarsCustomer(this.id)
        .then((res) => {
          this.customersList = res || [];
        })
        .finally(() => {
          this.customersLoading = false;
        });
    },
  },
  mounted() {
    this.getCarDetail();
    this.getCustomers();
  },
};
</script>

<style lang="less" scoped>
.page-car-info {
  .header-info {
    margin-bottom: 25px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(221, 221, 223, 1);
    .car-name {
      font-size: 34px;
      line-height: 36px;
    }
  }
  .base-info {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    padding: 2px 24px 6px 20px;
    background: #ffffff;
    /* M - 04 dp - card */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.04),
      0px 3px 6px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    /deep/ .el-row {
      padding: 22px 0 22px 0;
    }
    .header-img {
      width: 226px;
      flex: 0 0 auto;
      padding: 40px 0 0 24px;
      img {
        width: 160px;
        height: 160px;
        border-radius: 50%;
      }
    }
    .detail {
      flex: 1 1 auto;
    }
    .title {
      min-width: 20px;
      min-height: 20px;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
    }
    .label {
      font-size: 12px;
      line-height: 19px;
      color: #747378;
    }
    .value {
      font-size: 16px;
      line-height: 25px;
    }
  }
}
</style>
