<template>
  <div class="table-wrapper">
    <img
      @click="showQuery = !showQuery"
      class="showQuery"
      src="@/assets/img/query-more.png"
      alt=""
    />
    <el-tabs v-model="activeName">
      <el-tab-pane label="客户列表" name="客户列表">
        <customer-list
          ref="customerList"
          :showQuery.sync="showQuery"
        ></customer-list>
      </el-tab-pane>
      <el-tab-pane label="车辆列表" name="车辆列表">
        <car-list ref="carList" :showQuery.sync="showQuery"></car-list>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import customerList from "./customer-list.vue";
import carList from "./car-list.vue";
export default {
  components: { customerList, carList },
  data() {
    return {
      activeName: "客户列表",
      showQuery: false,
    };
  },
  methods: {
    refresh() {
      this.$refs.customerList.refresh();
      this.$refs.carList.refresh();
    },
  },
};
</script>

<style lang="less" scoped>
.table-wrapper {
  padding: 24px;
  padding-bottom: 34px;
  background: #ffffff;
  position: relative;
  /* M - 04 dp - card */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.04),
    0px 3px 6px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;

  /deep/ .el-tabs__header {
    margin-bottom: 0;
  }
  /deep/ .el-tabs__item {
    // font-size: 20px;
    // line-height: 32px;
    font-weight: 500;
  }
  .showQuery {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
    z-index: 3;
  }
}
</style>
