<template>
  <div>
    <!-- 生命周期 -->
    <div class="life-cycle">
      <div class="start"></div>
      <div
        :class="[
          'life-cycle-item',
          {
            cur: item === currentJourney,
            beforeCur: index < JourneyList.indexOf(currentJourney),
          },
        ]"
        v-for="(item, index) in JourneyList"
        :key="item"
      >
        <div class="life-cycle-circle">
          <div class="icon">
            <i :class="iconEnum[item]"></i>
          </div>
          <div class="text">
            {{ item }}
          </div>
        </div>
      </div>
      <div class="end"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    currentJourney: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "CustomerJourney",
    },
  },
  computed: {
    ...mapGetters({
      customerFields: "common/customerFields",
      carFields: "common/carFields",
    }),
    JourneyList() {
      if (this.type === "CustomerJourney") {
        return this.customerJourneyList();
      } else if (this.type === "CarJourney") {
        return this.carJourneyList();
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      iconEnum: {
        粉丝: "iconfont icon-Customer-Icons1",
        线索: "iconfont icon-Customer-Icons2",
        意向: "iconfont icon-Customer-Icons3",
        潜客: "iconfont icon-Customer-Icons4",
        新车主: "iconfont icon-Customer-Icons5",
        老车主: "iconfont icon-Customer-Icons6",
        下订: "iconfont icon-dingdan",
        交车: "iconfont icon-jiaoche",
        保内: "iconfont icon-baonei",
        保外: "iconfont icon-baowai",
        "置换/二手车": "iconfont icon-a-zhihuanershouche",
      },
    };
  },
  methods: {
    // 获取客户生命周期
    customerJourneyList() {
      const JourneyList1 = this.customerFields.find((item) => {
        return item.APIName === "CustomerJourney";
      });
      return JourneyList1 && JourneyList1.PicklistOptions;
    },
    // 获取车辆生命周期
    carJourneyList() {
      const JourneyList1 = this.carFields.find((item) => {
        return item.APIName === "CarJourney";
      });
      return JourneyList1 && JourneyList1.PicklistOptions;
      // return ["下订", "交车", "保内", "保外", "置换/二手车"];
    },
  },
};
</script>

<style lang="less" scoped>
.life-cycle {
  display: flex;
  // width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 6px;
  background: #e2e5e9;
  margin: 47px 100px 80px 100px;
  .life-cycle-item {
    position: relative;
    .life-cycle-circle {
      display: flex;
      flex-direction: column;
      align-items: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(255, 255, 255, 100);
      font-size: 12px;
      position: relative;
      z-index: 2;
      width: 60px;
      .icon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #e2e5e9;
        border: 1px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1),
          0px 4px 12px rgba(0, 0, 0, 0.04), 0px 3px 6px rgba(0, 0, 0, 0.04),
          0px 0px 4px rgba(0, 0, 0, 0.06);
        .iconfont {
          color: #747378;
          font-size: 36px;
        }
      }
      .text {
        position: absolute;
        top: 70px;
        color: #747378;
        font-weight: 500;
        font-size: 16px;
        line-height: 1;
        width: 120px;
        text-align: center;
      }
    }
    &.cur {
      .life-cycle-circle {
        .icon {
          background: #37b3a7;
          border: 3px solid #fff;
          box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.04),
            0px 6px 12px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
          .iconfont {
            color: #ffffff;
          }
        }
        .text {
          color: #26262c;
        }
      }
    }
    &.beforeCur {
      .life-cycle-circle {
        .icon {
          background: #5fcec3;
          color: #ffffff;
          .iconfont {
            color: #ffffff;
            font-size: 36px;
          }
        }
      }
    }
  }
  // .life-cycle-item::before {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   width: 100%;
  //   // border: 1px dashed #4f87ec;
  //   height: 10px;
  //   background: #bbbbbb;
  //   z-index: 1;
  // }
  // .stop::before {
  //   border-color: #bbbbbb;
  // }
  // .stop {
  //   .life-cycle-circle {
  //     background: #e1e3e6;
  //     color: #595858;
  //   }
  // }
  // .life-cycle-item:nth-last-of-type(1) {
  //   flex: 1;
  // }
  // .life-cycle-item:nth-last-of-type(1)::after {
  //   content: "";
  //   position: absolute;
  //   height: 0;
  //   width: 0;
  //   right: -30px;
  //   border: 10px solid transparent;
  //   border-left: 20px solid #bbbbbb;
  //   top: 50%;
  //   transform: translateY(-50%);
  // }
  .start {
    position: relative;

    &::before {
      content: "";
      width: 20px;
      height: 20px;
      background: #e2e5e9;
      border-radius: 50%;
      position: absolute;
      left: -22px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .end {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      border: 15px solid transparent;
      border-left: 15px solid #e2e5e9;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
  }
}
</style>
