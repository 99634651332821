<template>
  <div class="innerBox">
    <div class="left">
      <div class="timelineBox" v-for="item in data" :key="item.time">
        <div
          class="timeline"
          :class="active === item.time ? 'active' : ''"
          @click="handleToggle(item.time)"
        >
          <div>{{ item.time }}</div>
          <div class="node"></div>
        </div>
        <div class="line"></div>
      </div>
    </div>

    <div class="content">
      <el-card v-for="parent in contentList" :key="parent.time" class="card">
        <h3>{{ parent.time }}</h3>
        <p v-for="item in parent.list" :key="item.time">
          <span>{{ item.BehaviorTime }}：</span>
          <span>{{ item.BehaviorOwner }}</span>
          <span v-html="'&#12288;'" />
          <span>{{ item.BehaviorChannelType }}|{{ item.BehaviorChannel }}</span>
          <span v-html="'&#12288;'" />
          <span>{{ item.Behavior }}</span>
        </p>
      </el-card>
    </div>
  </div>
</template>
<script>
import { apiGetCustomerBeha, apiGetCarBeha } from "@/api/group";
export default {
  props: {
    customerBehaviorsTimeLine: {
      type: Array,
      default: () => {
        return [];
      },
    },
    id: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "customer", // customer, car
    },
  },
  data() {
    return {
      active: "2021",
      data: [
        // {
        //   time: "2021", // 年
        //   list: [
        //     {
        //       time: "2021年8月",
        //       list: [
        //         {
        //           time: "2021年8月11日 12:11",
        //           desc: "微信公众号|活动触达",
        //         },
        //         {
        //           time: "2021年8月10日 12:11",
        //           desc: "微信公众号|活动触达",
        //         },
        //       ],
        //     },
        //     {
        //       time: "2021年7月",
        //       list: [
        //         {
        //           time: "2021年7月11日 12:11",
        //           desc: "微信公众号|活动触达",
        //         },
        //         {
        //           time: "2021年7月10日 12:11",
        //           desc: "微信公众号|活动触达",
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   time: "2020",
        //   list: [
        //     {
        //       time: "2020年8月",
        //       list: [
        //         {
        //           time: "2020年8月11日 12:11",
        //           desc: "微信公众号|活动触达",
        //         },
        //         {
        //           time: "2020年8月10日 12:11",
        //           desc: "微信公众号|活动触达",
        //         },
        //       ],
        //     },
        //     {
        //       time: "2020年7月",
        //       list: [
        //         {
        //           time: "2020年7月11日 12:11",
        //           desc: "微信公众号|活动触达",
        //         },
        //         {
        //           time: "2020年7月10日 12:11",
        //           desc: "微信公众号|活动触达",
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   time: "2019",
        //   list: [],
        // },
        // {
        //   time: "2018",
        //   list: [],
        // },
      ],
    };
  },
  computed: {
    contentList() {
      let list = [];
      if (this.active) {
        const find = this.data.find((item) => {
          return item.time === this.active;
        });
        if (find) {
          list = find.list;
        }
      }
      return list;
    },
  },
  methods: {
    handleToggle(time) {
      this.active = time;
    },

    // 获取客户行为
    getCustomerBeha() {
      apiGetCustomerBeha(this.id, { isFormat: 1 }).then((res) => {
        let list = res.behaviors || [];
        this.data = list;
        this.active = (list[0] && list[0].time) || new Date().getFullYear();
      });
    },
    // 获取车行为
    getCarBeha() {
      apiGetCarBeha(this.id, { isFormat: 1 }).then((res) => {
        let list = res.behaviors || [];
        this.data = list;
        this.active = (list[0] && list[0].time) || new Date().getFullYear();
      });
    },
  },
  created() {
    if (this.type === "car") {
      this.getCarBeha();
    } else {
      this.getCustomerBeha();
    }
  },
};
</script>
<style lang="less" scoped>
.innerBox {
  display: flex;
  margin-top: 20px;
}
.timelineBox {
  width: 70px;

  .timeline {
    display: flex;
    cursor: pointer;

    color: #909399;
    line-height: 1;
    font-size: 13px;
    .node {
      background-color: #e4e7ed;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12px;
      height: 12px;
      margin-left: 5px;
    }

    &.active {
      color: #409eff;

      .node {
        background-color: #409eff;
      }
    }
  }

  .line {
    border-left: 2px solid #e4e7ed;
    height: 100px;
    margin-left: 40px;
  }
}
.content {
  flex: 1;

  .card {
    margin-bottom: 10px;
  }
}
</style>
