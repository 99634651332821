import { render, staticRenderFns } from "./customer-chart.vue?vue&type=template&id=59ba9f18&scoped=true&"
import script from "./customer-chart.vue?vue&type=script&lang=js&"
export * from "./customer-chart.vue?vue&type=script&lang=js&"
import style0 from "./customer-chart.vue?vue&type=style&index=0&id=59ba9f18&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59ba9f18",
  null
  
)

export default component.exports