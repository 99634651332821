<template>
  <div>
    <div class="query-form" v-if="showQuery">
      <i class="el-icon-close" @click="$emit('update:showQuery', false)"></i>
      <el-form
        :inline="true"
        v-if="showQuery"
        label-width="80px"
        ref="searchForm"
      >
        <el-form-item label="VIN:">
          <el-input
            placeholder="VIN"
            v-model="formData.Vin"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="车牌:">
          <el-input
            placeholder="车牌"
            v-model="formData.License"
            style="width: 200px"
          ></el-input>
        </el-form-item>

        <div style="display: inline-block">
          <el-form-item label="车龄介于:" prop="RecordCountLt">
            <el-input-number
              placeholder="最小值"
              v-model="formData.RecordCountLt"
              style="width: 120px"
              :min="0"
              clearable
              :controls="false"
              :precision="0"
            />
          </el-form-item>
          <div
            style="
              margin-left: 5px;
              margin-right: 8px;
              margin-top: 10px;
              display: inline-block;
            "
          >
            至
          </div>
          <el-form-item label="" prop="RecordCountRt">
            <el-input-number
              placeholder="最大值"
              v-model="formData.RecordCountRt"
              style="width: 120px"
              :min="0"
              clearable
              :controls="false"
              :precision="0"
            />
          </el-form-item>
        </div>
        <el-form-item label="品牌:">
          <el-select
            v-model="formData.CarBrand"
            style="width: 200px"
            clearable
            filterable
          >
            <el-option
              :value="item"
              :label="item"
              v-for="item in CarBrandList"
              :key="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车系:">
          <el-select
            v-model="formData.CarSeries"
            style="width: 200px"
            clearable
            filterable
          >
            <el-option
              :value="item"
              :label="item"
              v-for="item in CarSeriesList"
              :key="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-button size="medium" type="info" @click="handleQuery" round
          >应用</el-button
        >
        <el-button size="medium" @click="handleReset" round class="resetBtn"
          >重置</el-button
        >
      </el-form>
    </div>
    <el-table :data="tableData" v-loading="loading" class="table-customer">
      <el-table-column prop="Vin" label="VIN" width="320">
        <template slot-scope="scope">
          <router-link :to="`/car/info/${scope.row.Id}`" class="g-link">
            <i
              class="iconfont icon-car"
              style="margin-right: 6px; vertical-align: bottom"
            ></i>
            <span>{{ scope.row.Vin }}</span>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="License" label="车牌"> </el-table-column>
      <el-table-column prop="CarAge" label="车龄"> </el-table-column>
      <el-table-column prop="CarBrand" label="品牌"> </el-table-column>
      <el-table-column prop="CarSeries" label="车系"> </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      layout="prev, pager, next"
      :total="pagination.totalCount"
      :current-page="pagination.currentPage"
      :page-size="pagination.pageSize"
      @current-change="handlePage"
    >
    </el-pagination>
  </div>
</template>

<script>
import { apiGetCars } from "@/api/group";
import { mapGetters } from "vuex";
export default {
  props: {
    showQuery: {
      type: Boolean,
      default: false,
    },
  },
  inject: ["id"],
  data() {
    const validateRecordCountLt = (rule, value, callback) => {
      console.log(rule, value);
      if (typeof value !== "undefined") {
        if (typeof this.formData.RecordCountRt !== "undefined") {
          this.$refs.ruleForm.validateField("RecordCountRt");
          callback();
        } else {
          callback();
        }
      } else {
        // 没有填值
        if (typeof this.formData.RecordCountRt !== "undefined") {
          // 如果右边值填了，左边必须填。
          callback(new Error("请输入小于右边的整数"));
        } else {
          callback();
        }
      }
    };

    const validateRecordCountRt = (rule, value, callback) => {
      console.log(rule, value);
      if (typeof value !== "undefined") {
        if (value <= this.formData.RecordCountLt) {
          callback(new Error("右边整数需比左边大"));
        } else {
          callback();
        }
      } else {
        // 没有填值
        if (typeof this.formData.RecordCountLt !== "undefined") {
          // 如果左边值填了，右边必须填。
          callback(new Error("请输入大于左边的整数"));
        } else {
          callback();
        }
      }
    };

    return {
      loading: false,
      tableData: [],
      pagination: {
        pageSize: 10,
        totalCount: 0,
        currentPage: 1,
      },
      formData: {
        Vin: "", // 车辆Vin
        License: [], // 车牌
        RecordCountLt: undefined,
        RecordCountRt: undefined,
        CarBrand: "", // 车辆品牌
        CarSeries: "", // 车系
      },
      formRule: {
        RecordCountLt: [
          {
            validator: validateRecordCountLt,
            trigger: "blur",
          },
        ],
        RecordCountRt: [{ validator: validateRecordCountRt, trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapGetters({
      carFields: "common/carFields",
    }),
    // 获取车辆品牌列表
    CarBrandList() {
      const PicklistOptions = this.carFields.find((item) => {
        return item.APIName === "CarBrand";
      }).PicklistOptions;
      return PicklistOptions;
    },
    // 获取车系列表
    CarSeriesList() {
      const PicklistOptions = this.carFields.find((item) => {
        return item.APIName === "CarSeries";
      }).PicklistOptions;
      return PicklistOptions;
    },
  },
  methods: {
    getCustomers() {
      this.loading = true;
      const { pageSize, currentPage } = this.pagination;
      const formData = JSON.parse(JSON.stringify(this.formData));

      formData.CarMinAge = formData.RecordCountLt;
      formData.CarMaxAge = formData.RecordCountRt;
      delete formData.RecordCountLt;
      delete formData.RecordCountRt;

      const param = {
        offset: pageSize * (currentPage - 1),
        limit: pageSize,
        ...formData,
      };
      apiGetCars(this.id, param)
        .then((res) => {
          this.tableData = res.cars;
          this.pagination.totalCount = res.meta.total_count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePage(index) {
      this.pagination.currentPage = index;
      this.getCustomers();
    },
    refresh() {
      this.handlePage(1);
    },
    handleQuery() {
      this.handlePage(1);
    },
    handleReset() {
      this.formData = {
        Vin: "", // 车辆Vin
        License: [], // 车牌
        RecordCountLt: undefined,
        RecordCountRt: undefined,
        CarBrand: "", // 车辆品牌
        CarSeries: "", // 车系
      };
    },
  },
  mounted() {
    this.getCustomers();
  },
};
</script>

<style lang="less" scoped>
.table-wrapper {
  .table-customer {
    margin-top: 0;
  }
  .showQuery {
    cursor: pointer;
  }
  .query-form {
    margin: 20px 0;
    background: #f6f7f8;
    border-radius: 6px;
    position: relative;
    padding: 16px 90px 2px 16px;
    .el-icon-close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    /deep/ .el-form {
      display: flex;
      flex-wrap: wrap;
    }
    /deep/ .el-button--info {
      margin-left: auto;
      margin-bottom: 10px;
      height: 40px;
    }
    .resetBtn {
      margin-bottom: 10px;
      height: 40px;
    }
  }

  .table-title {
    margin-bottom: 18px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
  }

  .slider {
    width: 162px;

    margin-left: 8px;
    margin-right: 20px;
    /deep/ .el-slider__bar {
      background: #0483ec;
    }
    /deep/ .el-slider__button {
      background: #0483ec;
      border: none;
    }
  }

  /deep/ .el-range-separator {
    padding: 0;
  }
}
</style>
