<template>
  <div>
    <div class="query-form" v-if="showQuery">
      <i class="el-icon-close" @click="$emit('update:showQuery', false)"></i>
      <el-form
        :inline="true"
        v-if="showQuery"
        label-width="120px"
        ref="searchForm"
      >
        <el-form-item label="客户姓名:">
          <el-input
            placeholder="客户姓名"
            v-model="formData.CustomerName"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="计划发送员工:" v-if="isComWx">
          <el-input
            placeholder="计划发送员工"
            v-model="formData.PlanToSendUser"
            style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item label="客户是否被触达:">
          <el-select
            v-model="formData.IfCustomerOrGroupRecevied"
            style="width: 200px"
            clearable
            filterable
          >
            <el-option
              :value="item.value"
              :label="item.label"
              v-for="item in userSendStatus"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="触达时间介于:">
          <el-date-picker
            v-model="formData.SendTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 100%"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-button size="medium" type="info" @click="handleQuery" round
          >应用</el-button
        >
        <el-button size="medium" @click="handleReset" round class="resetBtn"
          >重置</el-button
        >
      </el-form>
    </div>
    <el-table :data="tableData" v-loading="loading" class="table-customer">
      <el-table-column prop="CustomerName" label="客户姓名"> </el-table-column>
      <el-table-column prop="IfCustomerOrGroupRecevied" label="客户是否被触达">
      </el-table-column>
      <el-table-column prop="SendTime" label="触达时间"> </el-table-column>
      <el-table-column v-if="isComWx" prop="PlanToSendUser" label="计划发送员工">
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      layout="prev, pager, next"
      :total="pagination.totalCount"
      :current-page="pagination.currentPage"
      :page-size="pagination.pageSize"
      @current-change="handlePage"
    >
    </el-pagination>
  </div>
</template>

<script>
import { apiGetTriggerList } from "@/api/plan";
import { mapGetters } from "vuex";
export default {
  props: {
    showQuery: {
      type: Boolean,
      default: false,
    },
    isComWx: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      tableData: [],
      pagination: {
        pageSize: 10,
        totalCount: 0,
        currentPage: 1,
      },
      formData: {
        CustomerName: "", // 客户姓名
        PlanToSendUser: "", // 计划发送员工
        IfCustomerOrGroupRecevied: "", // 客户是否被触达
        SendTime: "", // 触达时间
      },
      formRule: {},
      userSendStatus: [
        {
          value: "是",
          label: "是",
        },
        {
          value: "否",
          label: "否",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    getCustomers() {
      this.loading = true;
      const { pageSize, currentPage } = this.pagination;
      const formData = JSON.parse(JSON.stringify(this.formData));

      const param = {
        offset: pageSize * (currentPage - 1),
        limit: pageSize,
        ...formData,
      };
      if (formData.SendTime) {
        param.SendTimeLt = formData.SendTime[0];
        param.SendTimeRt = formData.SendTime[1];
        delete param.SendTime;
      }

      apiGetTriggerList(this.id, param)
        .then((res) => {
          this.tableData = res.customerTriggerReport;
          this.pagination.totalCount = res.meta.total_count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePage(index) {
      this.pagination.currentPage = index;
      this.getCustomers();
    },
    refresh() {
      this.handlePage(1);
    },
    handleQuery() {
      this.handlePage(1);
    },
    handleReset() {
      this.formData = {
        UserName: "", // 员工姓名
        UserOrgName: "", // 企业名称
        IfCustomerOrGroupRecevied: "", // 是否完成发送任务
        MinCustomerOrGroupCount: undefined, // 最小客户数量
        MaxCustomerOrGroupCount: undefined, // 最大客户数量
        MinCustomerOrGroupSendCount: undefined, //最小客户触达数量
        MaxCustomerOrGroupSendCount: undefined, // 最大客户触达数量
        MinCustomerOrGroupSendPercent: undefined, // 最小触达率
        MaxCustomerOrGroupSendPercent: undefined, // 最大触达率 -- 100
      };
    },
  },
  mounted() {
    this.getCustomers();
  },
};
</script>

<style lang="less" scoped>
.table-wrapper {
  .table-customer {
    margin-top: 0;
  }
  .showQuery {
    cursor: pointer;
  }
  .query-form {
    margin: 20px 0;
    background: #f6f7f8;
    border-radius: 6px;
    position: relative;
    padding: 16px 90px 2px 16px;
    .el-icon-close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    /deep/ .el-button--info {
      position: absolute;
      right: 90px;
      bottom: 16px;
      background: #4d4b58;
    }
    .resetBtn {
      right: 10px;
      bottom: 16px;
      position: absolute;
    }
  }

  .table-title {
    margin-bottom: 18px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
  }

  .slider {
    width: 162px;

    margin-left: 8px;
    margin-right: 20px;
    /deep/ .el-slider__bar {
      background: #0483ec;
    }
    /deep/ .el-slider__button {
      background: #0483ec;
      border: none;
    }
  }

  /deep/ .el-range-separator {
    padding: 0;
  }
}
</style>
