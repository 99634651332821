<template>
  <div>
    <div class="query-form" v-if="showQuery">
      <i class="el-icon-close" @click="$emit('update:showQuery', false)"></i>
      <el-form
        :inline="true"
        v-if="showQuery"
        label-width="120px"
        ref="searchForm"
      >
        <el-form-item label="员工姓名:">
          <el-input
            placeholder="员工姓名"
            clearable
            v-model="formData.UserName"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业名称:">
          <el-input
            placeholder="企业名称"
            clearable
            v-model="formData.UserOrgName"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认发送:">
          <el-select
            v-model="formData.IfUserSend"
            style="width: 200px"
            clearable
            filterable
          >
            <el-option
              :value="item.value"
              :label="item.label"
              v-for="item in userSendStatus"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="目标:">
          <el-input
            placeholder="目标名称"
            clearable
            v-model="formData.ToUserBehaviorType"
            style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-button size="medium" type="info" @click="handleQuery" round
          >应用</el-button
        >
        <el-button size="medium" @click="handleReset" round class="resetBtn"
          >重置</el-button
        >
      </el-form>
    </div>
    <el-table :data="tableData" v-loading="loading" class="table-customer">
      <el-table-column prop="UserName" label="员工姓名" width="320">
      </el-table-column>
      <el-table-column prop="UserOrgName" label="企业名称"> </el-table-column>
      <el-table-column prop="IfUserSend" label="确认发送">
      </el-table-column>
      <el-table-column prop="ToUserBehaviorType" label="目标">
      </el-table-column>
      <el-table-column prop="ToUserPlanCount" label="目标数量">
      </el-table-column>
      <el-table-column prop="ToUserActualCount" label="实际数量">
      </el-table-column>
      <el-table-column prop="ToUserSuccessPercent" label="目标达成率%">
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      layout="prev, pager, next"
      :total="pagination.totalCount"
      :current-page="pagination.currentPage"
      :page-size="pagination.pageSize"
      @current-change="handlePage"
    >
    </el-pagination>
  </div>
</template>

<script>
import { apiGetReportList } from "@/api/plan";
import { mapGetters } from "vuex";
export default {
  props: {
    showQuery: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      tableData: [],
      pagination: {
        pageSize: 10,
        totalCount: 0,
        currentPage: 1,
      },
      formData: {
        UserName: "", // 员工姓名
        UserOrgName: "", // 企业名称
        IfUserSend: "", // 确认发送
        ToUserBehaviorType: "", // 目标
      },
      formRule: {
      },
      userSendStatus: [
        {
          value: "是",
          label: "是",
        },
        {
          value: "否",
          label: "否",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    getCustomers() {
      this.loading = true;
      const { pageSize, currentPage } = this.pagination;
      const formData = JSON.parse(JSON.stringify(this.formData));

      const param = {
        offset: pageSize * (currentPage - 1),
        limit: pageSize,
        ...formData,
      };
      apiGetReportList(this.id, param)
        .then((res) => {
          this.tableData = res.comGroupReports;
          this.pagination.totalCount = res.meta.total_count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePage(index) {
      this.pagination.currentPage = index;
      this.getCustomers();
    },
    refresh() {
      this.handlePage(1);
    },
    handleQuery() {
      this.handlePage(1);
    },
    handleReset() {
      this.formData = {
        UserName: "", // 员工姓名
        UserOrgName: "", // 企业名称
        IfUserSend: "", // 确认发送
        ToUserBehaviorType: "", // 目标
      };
    },
  },
  mounted() {
    //this.getCustomers();
  },
};
</script>

<style lang="less" scoped>
.table-wrapper {
  .table-customer {
    margin-top: 0;
  }
  .showQuery {
    cursor: pointer;
  }
  .query-form {
    margin: 20px 0;
    background: #f6f7f8;
    border-radius: 6px;
    position: relative;
    padding: 16px 90px 2px 16px;
    .el-icon-close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    /deep/ .el-button--info {
      position: absolute;
      right: 90px;
      bottom: 16px;
      background: #4d4b58;
    }
    .resetBtn {
      right: 10px;
      bottom: 16px;
      position: absolute;
    }
  }

  .table-title {
    margin-bottom: 18px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
  }

  .slider {
    width: 162px;

    margin-left: 8px;
    margin-right: 20px;
    /deep/ .el-slider__bar {
      background: #0483ec;
    }
    /deep/ .el-slider__button {
      background: #0483ec;
      border: none;
    }
  }

  /deep/ .el-range-separator {
    padding: 0;
  }
}
</style>
