<template>
  <div class="page-customer-info">
    <content-header
      :title="detail.CustomerName"
      :breadList="['客户中心', '分群画像', '客户信息']"
    >
    </content-header>

    <life-cycle
      :currentJourney="detail.CustomerJourney"
      style="display: none"
    ></life-cycle>
    <customer-chart :id="id"></customer-chart>
    <div class="base-info">
      <div class="header-img" v-if="!loading">
        <img :src="detail.Avatar" alt="" v-if="detail.Avatar" />
        <img
          v-else-if="detail.CustomerSex === '男'"
          src="../../assets/img/boy.jpg"
        />
        <img
          v-else-if="detail.CustomerSex === '女'"
          src="../../assets/img/girl.jpg"
        />
        <img v-else src="../../assets/img/nosex.jpg" />
      </div>
      <div class="detail">
        <el-row>
          <el-col :span="6">
            <div class="title">基本信息</div>
          </el-col>
          <el-col :span="6">
            <div class="label">客户姓名</div>
            <div class="value">{{ detail.CustomerName }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">性别</div>
            <div class="value">{{ detail.CustomerSex }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">年龄</div>
            <div class="value">{{ detail.CustomerAge }}</div>
          </el-col>
        </el-row>
        <el-row style="border-bottom: 1px solid #dddddf">
          <el-col :span="6">
            <div class="title"></div>
          </el-col>
          <el-col :span="6">
            <div class="label">联系方式</div>
            <div class="value">{{ detail.Phone }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">企业价值</div>
            <div class="value">¥{{ detail.EnterpriseValue }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">集团价值</div>
            <div class="value">¥{{ detail.GroupValue }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="title">更多信息</div>
          </el-col>
          <el-col :span="6">
            <div class="label">拥车数量</div>
            <div class="value">{{ detail.CarNumbers || 0 }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">星座</div>
            <div class="value">{{ detail.CustomerConstellation }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">客户生日月</div>
            <div class="value">{{ detail.BirthdayMonth }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="title"></div>
          </el-col>
          <el-col :span="6">
            <div class="label">常住省份</div>
            <div class="value">{{ detail.Province }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">常住城市</div>
            <div class="value">{{ detail.City }}</div>
          </el-col>
          <el-col :span="6">
            <div class="label">常住城市级别</div>
            <div class="value">{{ detail.CityLevel }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="title"></div>
          </el-col>
          <el-col :span="6">
            <div class="label">常住区县</div>
            <div class="value">{{ detail.County }}</div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- <customer-behavior
      :customerBehaviorsList="customerBehaviorsList"
    ></customer-behavior> -->
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick"
      class="tabs-wrapper"
      v-if="!loading"
    >
      <el-tab-pane label="时间轴" name="first">
        <customerTimeline
          :id="detail.CustomerId"
          :customerBehaviorsTimeLine="customerBehaviorsTimeLine"
        />
      </el-tab-pane>
      <el-tab-pane label="关联的车辆信息" name="second">
        <car-table :tableData="carsList" :loading="carsLoading"></car-table>
      </el-tab-pane>
      <el-tab-pane label="连接渠道" name="third">
        <customerChannel :id="detail.CustomerId" type="customer" />
      </el-tab-pane>
      <el-tab-pane label="集团会员卡券" name="fourth">
        <customerVipCoupon :id="detail.CustomerId" type="customer" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ContentHeader from "@/views/layout/content-header";
import carTable from "./components/car-table";
// import customerBehavior from "./components/customer-behavior";
import { apiGetCustomerDetail, apiGetCustomerCars } from "@/api/group";
import lifeCycle from "@/components/life-cycle";
import customerChart from "./components/customer-chart.vue";
import customerTimeline from "./components/customer-timeline.vue";
import customerChannel from "./components/customer-channel.vue";
import customerVipCoupon from "./components/customer-vipCoupon.vue";
import { apiGetCustomerBeha } from "@/api/group";
export default {
  components: {
    ContentHeader,
    carTable,
    // customerBehavior,
    lifeCycle,
    customerChart,
    customerTimeline,
    customerChannel,
    customerVipCoupon,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {
        Cars: [],
      },
      activeName: "first",
      customerBehaviorsList: [],
      customerBehaviorsTimeLine: [],
      loading: true,
      carsList: [],
      carsLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      customerFields: "common/customerFields",
    }),
  },

  methods: {
    handleClick(tab) {
      this.activeName = tab.name;
    },
    getCustomerDetail() {
      this.loading = true;
      // const id = this.$route.params.id;
      apiGetCustomerDetail(this.id).then((res) => {
        this.loading = false;
        try {
          Object.keys(res).forEach((field) => {
            const find = this.customerFields.find((item) => {
              return item.APIName === field;
            });
            if (find) {
              if (find.DataType === "Decimal") {
                if (res[field] && res[field].toLocaleString) {
                  res[field] = res[field].toLocaleString();
                }
              }
            }
          });
        } catch (error) {
          console.log(error);
        }
        this.detail = res;
      });
    },
    getCars() {
      this.carsLoading = true;
      apiGetCustomerCars(this.id)
        .then((res) => {
          this.carsList = res || [];
        })
        .finally(() => {
          this.carsLoading = false;
        });
    },
    // 获取客户行为
    getCustomerBeha() {
      apiGetCustomerBeha(this.id).then((res) => {
        let list = res.behaviors;
        // this.customerBehaviorsList = list;
        this.customerBehaviorsTimeLine = list;
      });
    },
  },
  mounted() {
    this.getCustomerDetail();
    this.getCars();
    // this.getCustomerBeha();
  },
  filters: {
    qianwf(val) {
      if (!val) {
        return 0;
      }
      let str = "" + val;
      let list = [];
      let index = 0;
      for (let i = str.length - 1; i >= 0; i--) {
        list.unshift(str[i]);
        index++;
        if (index >= 3 && i > 0) {
          list.unshift(",");
          index = 0;
        }
      }
      return list.join("");
    },
  },
};
</script>

<style lang="less" scoped>
.page-customer-info {
  .base-info {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    padding: 2px 24px 6px 20px;
    background: #ffffff;
    /* M - 04 dp - card */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.04),
      0px 3px 6px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    /deep/ .el-row {
      padding: 22px 0 22px 0;
    }
    .header-img {
      width: 226px;
      flex: 0 0 auto;
      padding: 40px 0 0 24px;
      img {
        width: 160px;
        height: 160px;
        border-radius: 50%;
      }
    }
    .detail {
      flex: 1 1 auto;
    }
    .title {
      min-width: 20px;
      min-height: 20px;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
    }
    .label {
      font-size: 12px;
      line-height: 19px;
      color: #747378;
    }
    .value {
      font-size: 16px;
      line-height: 25px;
    }
  }
}

.tabs-wrapper {
  margin-bottom: 20px;
  padding: 24px;
  padding-bottom: 34px;
  background: #ffffff;
  /* M - 04 dp - card */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.04),
    0px 3px 6px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
</style>
