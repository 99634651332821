<template>
  <div>
    <content-header :title="detail.Name" :breadList="['客户中心', '分群画像']">
      <div slot="tools" class="top-tool-wrap">
        <el-button
          class="btn-edit"
          type="primary"
          size="small"
          round
          @click="handleEdit"
        >
          <i class="el-icon-edit" />
          <span> 编辑</span>
        </el-button>
        <i class="el-icon-loading g-icon-gray" v-if="loading" />
        <i
          class="el-icon-refresh-right g-icon-gray"
          v-else
          @click="handleRefresh"
        />
        <el-switch v-model="showCharts" inactive-text="显示分群概貌">
        </el-switch>
      </div>
    </content-header>
    <!-- <div class="condition-wrap">
      <span
        class="cond-item"
        v-for="(item, index) in conditions"
        :key="item._id"
      >
        <span class="apiname">{{ item.FieldAPIName }}</span>
        <span class="operator">{{ item.Operator }}</span>
        <span class="value">{{ item.Value }}</span>
        <span class="cond-type" v-if="index < conditions.length - 1">{{
          detail.FilterConditionType === "AND" ? "和" : "或"
        }}</span>
      </span>
    </div> -->
    <div class="condition-wrap" v-html="conditions"></div>
    <div class="chart-wrap" v-show="showCharts">
      <chart-list :id="groupId" ref="chartList" type="group"></chart-list>
    </div>
    <customer-car-tabs ref="customerCarList"></customer-car-tabs>
    <!-- <customer-list :id="groupId" ref="customerList"></customer-list> -->
    <edit-drawer
      :exist.sync="existDrawer"
      :saveType="editType"
      :detail="detail"
      @success="handleSaved"
    ></edit-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ContentHeader from "@/views/layout/content-header";
import chartList from "./components/chart-list";
// import customerList from "./components/customer-list";
import EditDrawer from "./components/edit-drawer";
import { apiGetGroupDetail } from "@/api/group";
import { apiGetCustomerPermission } from "@/api/auth";
import customerCarTabs from "./components/customer-car-tabs.vue";
export default {
  components: {
    ContentHeader,
    chartList,
    // customerList,
    EditDrawer,
    customerCarTabs,
  },
  data() {
    return {
      loading: false,
      groupId: this.$route.params.id,
      showCharts: true,
      existDrawer: false,
      detail: {
        _id: "",
        Name: "",
        IsPublic: "1",
        FilterConditionType: "",
        FilterConditionLogic: "",
        FilterGroups: [],
      },
      editType: "edit",
    };
  },
  computed: {
    ...mapGetters({
      allFields: "common/allFields",
      allOperator: "common/allOperator",
    }),
    // conditions() {
    //   const { FilterGroups } = this.detail;
    //   let result = [];
    //   FilterGroups.forEach((group) => {
    //     group.FilterItems.forEach((item) => {
    //       const field =
    //         this.allFields.find((f) => f.APIName === item.FieldAPIName) || {};
    //       const cond = {
    //         FieldAPIName: field.Name,
    //         Operator: this.allOperator[item.Operator],
    //         Value: item.Value,
    //       };
    //       result = result.concat(cond);
    //     });
    //   });
    //   return result;
    // },
    conditions() {
      const { FilterWhereClauseDisplay = "" } = this.detail;

      return FilterWhereClauseDisplay.replace(
        /(或者|并且)/g,
        `
      <span class="cond-type">$1</span>
      `
      );
    },
  },
  provide() {
    return {
      id: this.groupId,
    };
  },
  methods: {
    handleEdit() {
      // 请求编辑权限
      apiGetCustomerPermission(this.groupId, {
        target: "edit",
      }).then((res) => {
        if (res.PermissionEdit) {
          this.editType = "edit";
          this.existDrawer = true;
        } else {
          // this.$message.error("无编辑权限");
          this.editType = "show";
          this.existDrawer = true;
        }
      });
    },
    getGroupDetail() {
      this.loading = true;
      apiGetGroupDetail(this.groupId)
        .then((res) => {
          this.detail = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSaved() {
      this.existDrawer = false;
      this.handleRefresh();
    },
    handleRefresh() {
      this.getGroupDetail();
      this.$refs.customerCarList.refresh();
      this.$refs.chartList.refresh();
    },
  },
  mounted() {
    this.getGroupDetail();
  },
};
</script>

<style lang="less">
.top-tool-wrap {
  .btn-edit,
  .el-icon-loading,
  .el-icon-refresh-right {
    margin-right: 18px;
  }
}
.condition-wrap {
  margin-bottom: 20px;
  padding: 20px 20px 22px 20px;
  background: #ffffff;
  border: 1px solid #e2e6e9;
  border-radius: 12px;
  font-size: 12px;
  .operator {
    display: inline-block;
    height: 22px;
    line-height: 22px;
    margin: 0 8px;
    padding: 0 7px;
    background: #f6f7f8;
    border-radius: 4px;
    color: #747378;
  }
  .value {
  }
  .cond-type {
    display: inline-block;
    height: 22px;
    line-height: 22px;
    margin: 0 10px 0 10px;
    padding: 0 7px;
    background: #546169;
    border-radius: 4px;
    color: #fff;
  }
}
</style>
