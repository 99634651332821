<template>
  <div class="edit-drawer">
    <el-drawer
      :visible.sync="exist"
      :direction="direction"
      :before-close="handleClose"
      :show-close="false"
      :wrapperClosable="false"
    >
      <div slot="title" class="title-wrap">
        <div class="left">
          <i class="el-icon-close" @click="handleClose"></i>
          <span>客户数据下载</span>
        </div>
      </div>
      <div class="drawer-content">
        <div>
          <cond-card>
            <p class="tip">
              请现在单个客户分组内通过导出功能生成客户数据文件，然后在此处下载。系统仅保留最近30天的导出数据。
            </p>
            <el-table
              :data="tableData"
              v-loading="loading"
              class="table-customer"
            >
              <el-table-column prop="FileName" label="客户数据文件名称">
              </el-table-column>

              <el-table-column
                prop="CreatedDate"
                label="导出时间"
                width="180px"
              ></el-table-column>
              <el-table-column prop="CreatedByUser" label="导出用户">
              </el-table-column>
              <el-table-column prop="Status" label="任务状态">
              </el-table-column>
              <el-table-column prop="ErrorMessage" label="错误提示">
              </el-table-column>
              <el-table-column prop="address" label="操作" width="80px">
                <template slot-scope="scope">
                  <div class="tool-wrap">
                    <el-button type="text" @click="handleDownload(scope.row)" v-if="scope.row.FileURL">
                      <a :href="scope.row.FileURL" target="_blank">
                        <i
                          class="el-icon-download"
                          style="font-size: 20px; margin-left: 5px"
                        />
                      </a>
                    </el-button>
                    <el-button type="text" v-else disabled>
                      <i
                          class="el-icon-download"
                          style="font-size: 20px; margin-left: 5px"
                        />
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              class="pagination"
              layout="prev, pager, next"
              :total="pagination.totalCount"
              :current-page="pagination.currentPage"
              :page-size="pagination.pageSize"
              @current-change="handlePage"
            >
            </el-pagination>
          </cond-card>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import condCard from "@/components/cond-card";
import { apiCustomerGroupExported } from "@/api/group";
import { apiReportClick } from "@/api/common";

export default {
  components: { condCard },
  props: {
    exist: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      tableData: [],
      direction: "rtl",
      pagination: {
        pageSize: 10,
        totalCount: 0,
        currentPage: 1,
      },
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    handleClose() {
      this.$emit("update:exist", false);
    },
    handleDownload(row) {
      if (row.FileURL) {
        apiReportClick({
          event_type: "DOWNLOAD_FILE",
          event_id: row._id,
        });
      }
    },
    getList() {
      this.loading = true;
      const { pageSize, currentPage } = this.pagination;
      const param = {
        offset: pageSize * (currentPage - 1),
        limit: pageSize,
      };
      apiCustomerGroupExported(param)
        .then((res) => {
          this.tableData = res.jobs;
          this.pagination.totalCount = res.meta.total_count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePage(index) {
      this.pagination.currentPage = index;
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.edit-drawer {
  /deep/ .el-drawer {
    width: 72% !important;
    background: #f6f7f8;

    .el-drawer__header {
      margin-bottom: 16px;
      padding: 0;
    }

    .el-drawer__body {
      padding: 0 30px;
      overflow: auto;
    }
  }

  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 30px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);

    .left {
      font-size: 24px;
      line-height: 32px;

      i {
        margin-right: 22px;
        cursor: pointer;
      }
    }
  }
  .tip {
    font-size: 12px;
  }
}
</style>
