<template>
  <div class="table-wrapper">
    <el-table
      :data="tableData"
      class="table-customer"
      v-if="type === 'customer'"
    >
      <el-table-column prop="OrgName" label="组织名称"> </el-table-column>
      <el-table-column prop="RegisterTime" label="注册时间"> </el-table-column>
      <el-table-column prop="Score" label="有效积分"> </el-table-column>
    </el-table>
    <el-table :data="tableData" class="table-customer" v-if="type === 'car'">
      <el-table-column prop="OrgName" label="组织名称"> </el-table-column>
      <el-table-column prop="RegisterTime" label="注册时间"> </el-table-column>
      <el-table-column prop="Score" label="有效积分"> </el-table-column>
      <el-table-column prop="Recharge" label="剩余充值"> </el-table-column>
    </el-table>

    <el-pagination
      class="pagination"
      layout="prev, pager, next"
      :total="pagination.totalCount"
      :current-page="pagination.currentPage"
      :page-size="pagination.pageSize"
      @current-change="handlePage"
    >
    </el-pagination>
  </div>
</template>

<script>
// 会员信息
import {
  apiGetPointsAndRecharges,
  apiGetReadDealerPointsAndRecharges,
} from "@/api/group";
export default {
  props: {
    id: {
      type: String,
      default: () => "",
    },
    type: null, // car,customer
  },
  data() {
    return {
      pagination: {
        pageSize: 10,
        totalCount: 0,
        currentPage: 1,
      },
      loading: false,
      tableData: [],
    };
  },
  methods: {
    getVipList() {
      this.loading = true;
      const { pageSize, currentPage } = this.pagination;
      const param = {
        offset: pageSize * (currentPage - 1),
        limit: pageSize,
      };
      if (this.type === "car") {
        apiGetReadDealerPointsAndRecharges(this.id, param)
          .then((res) => {
            this.tableData = res.dealerPointsAndRecharges;
            this.pagination.totalCount = res.meta.total_count;
          })
          .finally(() => {
            this.loading = false;
          });
      }
      if (this.type === "customer") {
        apiGetPointsAndRecharges(this.id, param)
          .then((res) => {
            this.tableData = res.pointsAndRecharges;
            this.pagination.totalCount = res.meta.total_count;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    handlePage(index) {
      this.pagination.currentPage = index;
      this.getCustomers();
    },
  },
  mounted() {
    this.getVipList();
  },
};
</script>

<style lang="less" scoped>
.table-wrapper {
  //margin-bottom: 20px;
  // padding: 24px;
  //padding-bottom: 34px;
  background: #ffffff;
  /* M - 04 dp - card */

  // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.04),
  //   0px 3px 6px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(0, 0, 0, 0.06);
  // border-radius: 12px;
  .table-title {
    margin-bottom: 18px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
  }
  .pagination {
    text-align: right;
    padding: 20px 0 42px 0;
  }
}
</style>
