<template>
  <div class="personas-wrap">
    <el-row :gutter="16">
      <el-col v-for="item in list" :key="item._id" :span="8 * item.ColumnSpan">
        <div class="card">
          <div class="header">
            <span class="title">{{ item.Title }}</span>
          </div>
          <div class="content">
            <ve-line
              v-if="item.ChartType === 'line'"
              :data="dataMap[item._id]"
              :settings="lineSetting"
              :extend="lineExtend"
              v-loading="item.loading"
              height="240px"
            ></ve-line>
            <ve-line
              v-if="item.ChartType === 'area'"
              :data="dataMap[item._id]"
              :settings="areaSetting"
              :extend="areaExtend"
              v-loading="item.loading"
              height="240px"
            ></ve-line>
            <ve-pie
              v-if="item.ChartType === 'pie'"
              :data="dataMap[item._id]"
              :settings="pieSetting"
              :extend="pieExtend"
              v-loading="item.loading"
              height="240px"
            ></ve-pie>
            <ve-ring
              v-if="item.ChartType === 'ring'"
              :data="dataMap[item._id]"
              :settings="ringSetting"
              :extend="ringExtend"
              v-loading="item.loading"
              height="240px"
            ></ve-ring>
            <ve-histogram
              v-if="item.ChartType === 'bar'"
              :data="dataMap[item._id]"
              :settings="barSetting"
              :extend="barExtend"
              v-loading="item.loading"
              height="240px"
            ></ve-histogram>
            <!-- <div
              :id="item._id"
              style="height: 240px"
              v-loading="item.loading"
              v-if="item.ChartType === 'bar'"
            ></div> -->
            <ve-radar
              v-if="item.ChartType === 'radar'"
              :data="dataMap[item._id]"
              :settings="radarSetting"
              :extend="item.extend"
              :legend-visible="false"
              v-loading="item.loading"
              height="240px"
            ></ve-radar>
            <ve-funnel
              v-if="item.ChartType === 'funnel'"
              :data="dataMap[item._id]"
              :settings="funnelSetting"
              :extend="funnelExtend"
              :legend-visible="false"
              v-loading="item.loading"
              height="240px"
            ></ve-funnel>
            <div
              :id="item._id"
              style="height: 240px"
              v-loading="item.loading"
              v-if="item.ChartType === 'scatter'"
            ></div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  apiGetChartList as apiGetGroupChartList,
  apiGetChartDetail as apiGetGroupChartDetail,
} from "@/api/group";
import {
  apiGetChartList as apiGetPlanChartList,
  apiGetChartDetail as apiGetPlanChartDetail,
} from "@/api/plan";
import { cloneDeep } from "lodash";
const defaultColor = [
  "#36A3FC",
  "#44DCEE",
  "#8CC270",
  "#F2B540",
  "#EB8F47",
  "#E44E93",
  "#B27AB8",
  "#999999",
];
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      list: [],
      dataMap: {},
      lineSetting: {},
      areaSetting: {
        area: true,
      },
      pieSetting: {
        radius: 70,
        offsetY: 140,
      },
      ringSetting: {
        radius: [70, 40],
        offsetY: 140,
        label: {
          show: false,
        },
      },
      barSetting: {},
      radarSetting: {},
      funnelSetting: {},
      lineExtend: {
        color: defaultColor,
      },
      areaExtend: {
        // xAxis: {
        //   name: "车价/万元",
        //   nameLocation: "end",
        // },
        color: defaultColor,
        grid: {
          bottom: 10,
        },
      },
      pieExtend: {
        color: defaultColor,
      },
      ringExtend: {
        color: defaultColor,
      },
      barExtend: {
        color: defaultColor,
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
          },
          {
            show: true,
            type: "slider",
            showDetail: false,
            bottom: "0%",
            height: 15,
            handleSize: "10%",
            start: 0,
            end: 100,
          },
        ],
        xAxis: {
          axisLabel: { rotate: 30 },
        },
        grid: {
          bottom: 25,
        },
        series: {
          type: "bar",
          barMaxWidth: 20,
        },
      },
      radarExtend: {
        color: defaultColor,
        radar: {
          indicator: [
            { max: 6500 },
            { max: 16000 },
            { max: 30000 },
            { max: 38000 },
            { max: 52000 },
          ],
        },
      },
      funnelExtend: {
        color: defaultColor,
      },
    };
  },
  methods: {
    getChartList() {
      let promise;
      if (this.type === "group") {
        promise = apiGetGroupChartList();
      } else {
        promise = apiGetPlanChartList();
      }
      promise.then((res) => {
        if (Array.isArray(res)) {
          res.forEach((item) => {
            item.loading = true;
            this.$set(this.dataMap, item._id, {
              columns: [],
              rows: [],
              setting: {},
              extend: {},
            });
          });
          this.list = res;

          if (this.type === "group") {
            this.getChartSerialGroup();
          } else {
            this.getChartSerialPlan();
          }
        }
      });
    },
    getChartSerialGroup() {
      // for (let index = 0; index < this.list.length; index++) {
      //   const item = this.list[index];
      //   this.getChartDetailGroup(item)
      //     .then(() => {})
      //     .finally(() => {
      //       item.loading = false;
      //     });
      // }
      let index = 0;
      const fetch = () => {
        if (index >= this.list.length) {
          return;
        }
        const item = this.list[index++];
        this.getChartDetailGroup(item)
          .then(() => {
            fetch();
          })
          .finally(() => {
            item.loading = false;
          });
      };
      fetch();
    },
    getChartSerialPlan() {
      // for (let index = 0; index < this.list.length; index++) {
      //   const item = this.list[index];
      //   this.getChartDetailPlan(item)
      //     .then(() => {})
      //     .finally(() => {
      //       item.loading = false;
      //     });
      // }
      let index = 0;
      const fetch = () => {
        if (index >= this.list.length) {
          return;
        }
        const item = this.list[index++];
        this.getChartDetailPlan(item)
          .then(() => {
            fetch();
          })
          .finally(() => {
            item.loading = false;
          });
      };
      fetch();
    },
    getChartDetailPlan(item) {
      const promise = apiGetPlanChartDetail(item._id, this.id);
      return promise.then((res) => {
        try {
          // type === 'plan'
          let columns = [];
          /**
           * "Data":[
            {
                "Status":"全量分群",
                "data":[
                  {
                    name: "1~2w公里",
                    value: 2,
                  },
                  {
                    name: "2~3w公里",
                    value: 3,
                  },
                  {
                    name: "3~4w公里",
                    value: 4,
                  },
                ]
            }
           ]
           */
          let rows = res.Data || [];
          let newRows = res.Data || []; // [{'7~8w公里': 1, '9-10公里': 3}]
          if (res.ChartType === "radar") {
            // 雷达图需要特殊处理
            columns.push(res.XAxis);
            const rowItem = {
              [res.XAxis]: res.Title,
            };
            let max = 0;
            let indicator = [];
            res.Data.forEach((item) => {
              columns.push(item[res.XAxis]);
              rowItem[item[res.XAxis]] = item[res.YAxis];
              max = Math.max(max, item[res.YAxis]);
              indicator.push({
                name: item[res.XAxis],
              });
            });
            indicator.forEach((item) => {
              item.max = max;
            });
            item.extend = cloneDeep(this.radarExtend);
            item.extend.radar.indicator = indicator;
            newRows = [rowItem];
          } else if (res.ChartType === "scatter") {
            this.initScatterPlan(item._id, res);
          } else {
            if (res.ChartType === "funnel") {
              columns = Object.keys(rows[0]).filter((key) => key === res.YAxis);
              columns.unshift(res.XAxis);
            } else {
              if (rows[0]) {
                (rows[0].data || []).forEach((item) => {
                  const key = item.name;
                  if (key) {
                    if (key !== res.XAxis) {
                      columns.push(key);
                    }
                  }
                });
                columns.unshift(res.XAxis);

                newRows = [];
                rows.forEach((itemObj) => {
                  const obj = {};
                  obj[res.XAxis] = itemObj[res.XAxis];

                  (itemObj.data || []).forEach((item) => {
                    if (item.name) {
                      obj[item.name] = item.value;
                    }
                  });
                  newRows.push(obj);
                });
              }
            }
          }

          // 将X轴的值都转成字符串，否则显示会有问题
          newRows.forEach((row) => {
            row[res.XAxis] = "" + row[res.XAxis];
          });

          this.dataMap[item._id] = {
            columns,
            rows: newRows,
            setting: this[res.ChartType + "Setting"],
            extend: this[res.ChartType + "Extend"],
          };
        } catch (error) {
          console.log(error);
        }
      });
    },
    refresh() {
      this.getChartList();
    },
    initScatterPlan(id, res) {
      const echarts = window.echarts;
      const chartDom = document.getElementById(id);
      if (!chartDom) {
        return;
      }
      var myChart = echarts.init(chartDom);
      var option;

      const xAxis = [];
      const yAxis = [];
      const data = [];
      /**
       *  
       * "Data":[
        {
            "Status":"全量分群",
            "data":[
                {
                    "name":"1~2w公里",
                    "value": 2
                },
                {
                    "name":"2~3w公里",
                    "value":2
                },
                {
                    "name":"3~4w公里",
                    "value":2
                }
            ]
        }
        ]
      */
      res.Data.forEach((itemObj) => {
        const keys = [];
        (itemObj.data || []).forEach((item) => {
          if (item.name) {
            keys.push(item.name);
          }
        });

        const newKeys = keys.filter((key) => {
          return key !== res.XAxis;
        });

        if (Reflect.has(itemObj, res.XAxis)) {
          if (xAxis.indexOf(itemObj[res.XAxis]) === -1) {
            xAxis.push(itemObj[res.XAxis]);
          }
        }

        newKeys.forEach((key) => {
          if (yAxis.indexOf(key) === -1) {
            yAxis.push(key);
          }
        });
      });

      const newRows = [];
      res.Data.forEach((itemObj) => {
        const obj = {};
        obj[res.XAxis] = itemObj[res.XAxis];

        (itemObj.data || []).forEach((item) => {
          if (item.name) {
            obj[item.name] = item.value;
          }
        });
        newRows.push(obj);
      });

      // 处理散点坐标集
      xAxis.forEach((xName, x) => {
        yAxis.forEach((yName, y) => {
          const list = [x, y];
          let value = null;
          newRows.find((item) => {
            if (item[res.XAxis] == xName) {
              value = item[yName];
            }
          });
          list.push(value);
          data.push(list);
        });
      });

      option = {
        legend: {
          data: [res.YAxis],
          left: "right",
          color: defaultColor[0],
        },
        tooltip: {
          position: "top",
          formatter: function (params) {
            return (
              xAxis[params.value[0]] +
              " - " +
              yAxis[params.value[1]] +
              " - " +
              params.value[2]
            );
          },
        },
        grid: {
          left: 2,
          bottom: 10,
          right: 10,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: xAxis,
          boundaryGap: false,
          splitLine: {
            show: true,
          },
          axisLine: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          data: yAxis,
          axisLine: {
            show: false,
          },
        },
        series: [
          {
            name: res.YAxis,
            type: "scatter",
            color: "#36A3FC",
            symbolSize: function (val) {
              return val[2] * 2;
            },
            data: data,
            animationDelay: function (idx) {
              return idx * 5;
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
    getChartDetailGroup(item) {
      const promise = apiGetGroupChartDetail(item._id, this.id);
      return promise.then((res) => {
        try {
          let columns = [];
          let rows = res.Data || [];

          if (res.ChartType === "radar") {
            // 雷达图需要特殊处理
            columns.push(res.XAxis);
            const rowItem = {
              [res.XAxis]: res.Title,
            };
            let max = 0;
            let indicator = [];
            res.Data.forEach((item) => {
              columns.push(item[res.XAxis]);
              rowItem[item[res.XAxis]] = item[res.YAxis];
              max = Math.max(max, item[res.YAxis]);
              indicator.push({
                name: item[res.XAxis],
              });
            });
            indicator.forEach((item) => {
              item.max = max;
            });
            item.extend = cloneDeep(this.radarExtend);
            item.extend.radar.indicator = indicator;
            rows = [rowItem];
          } else if (res.ChartType === "scatter") {
            this.initScatterGroup(item._id, res);
          } else {
            if (res.ChartType === "funnel") {
              columns = Object.keys(res.Data[0]).filter(
                (key) => key === res.YAxis
              );
              columns.unshift(res.XAxis);
            } else {
              columns = Object.keys(res.Data[0]).filter(
                (key) => key !== res.XAxis
              );
              columns.unshift(res.XAxis);
            }
          }

          // 将X轴的值都转成字符串，否则显示会有问题
          rows.forEach((row) => {
            row[res.XAxis] = "" + row[res.XAxis];
          });

          this.dataMap[item._id] = {
            columns,
            rows,
            setting: this[res.ChartType + "Setting"],
            extend: this[res.ChartType + "Extend"],
          };
        } catch (error) {
          console.log(error);
        }
      });
    },
    initScatterGroup(id, res) {
      const echarts = window.echarts;
      const chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom);
      var option;

      const xAxis = [];
      const yAxis = [];
      const data = [];
      res.Data.forEach((item) => {
        const keys = Object.keys(item);
        const newKeys = keys.filter((key) => {
          return key !== res.XAxis;
        });
        if (xAxis.indexOf(item[res.XAxis]) === -1) {
          xAxis.push(item[res.XAxis]);
        }
        newKeys.forEach((key) => {
          if (yAxis.indexOf(key) === -1) {
            yAxis.push(key);
          }
        });
      });

      // 处理散点坐标集
      xAxis.forEach((xName, x) => {
        yAxis.forEach((yName, y) => {
          const list = [x, y];
          let value = null;
          res.Data.find((item) => {
            if (item[res.XAxis] == xName) {
              value = item[yName];
            }
          });
          list.push(value);
          data.push(list);
        });
      });

      option = {
        legend: {
          data: [res.YAxis],
          left: "right",
          color: defaultColor[0],
        },
        tooltip: {
          position: "top",
          formatter: function (params) {
            return (
              xAxis[params.value[0]] +
              " - " +
              yAxis[params.value[1]] +
              " - " +
              params.value[2]
            );
          },
        },
        grid: {
          left: 2,
          bottom: 10,
          right: 10,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: xAxis,
          boundaryGap: false,
          splitLine: {
            show: true,
          },
          axisLine: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          data: yAxis,
          axisLine: {
            show: false,
          },
        },
        series: [
          {
            name: res.YAxis,
            type: "scatter",
            color: "#36A3FC",
            symbolSize: function (val) {
              return val[2] * 2;
            },
            data: data,
            animationDelay: function (idx) {
              return idx * 5;
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
  mounted() {
    this.getChartList();
  },
};
</script>

<style lang="less" scoped>
.personas-wrap {
  .card {
    margin-bottom: 22px;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 24px 36px rgba(0, 0, 0, 0.04), 0px 16px 32px rgba(0, 0, 0, 0.04),
      0px 0px 4px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    .header {
      margin-bottom: 14px;
      .title {
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
}
</style>
